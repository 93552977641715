@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800&family=Poppins:wght@700&display=swap');
body {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #191919;
    font-weight: 400;
    font-style: normal;
    background: #f8f8f8;
}
:root {
    --primary-color: #009900;
    --secondary-color: #FFA500;
}
a {
    text-decoration: none;
}
p {
    margin: 0;
    color: #848484;
  }
img {
    max-width: 100%;
  }
ol,ul {
    padding: 0;
    margin: 0;
}
li {
    list-style-type: none;
}
h1,h2,h3,h4,h5,h6 {
    color: #0E1017;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
}
.gr-border {
    background: #fff;
    border-radius: 10px;
  }
  .gr-border-grid {
    background: #fff;
    border-radius: 10px;
  }
  .gr-border {
    background: linear-gradient(90deg, #FFA500 26.23%, #009900 76.17%),linear-gradient(0deg, #FFFFFF, #FFFFFF);
    border-radius: 10px;
    padding: 4px;
  }
  .header-nav-box {
    padding: 15px;
    display: flex;
  }
.header-link-right {
    margin-left: auto;
  }
  .header-link-right li {
    display: inline-block;
    font-weight: 500;
    position: relative;
  }  
.header-link-right li:not(:first-child):before {
  content: "";
  position: absolute;
  width: 1px;
  height: 15px;
  background: var(--secondary-color);
  left: -10px;
  top: 5px;
}
  .header-box {
    border: 2px solid var(--primary-color);
    border-radius: 10px;
    padding: 10px;
    text-align: center;
  }
  .header-box a {
    color: #000;
  }
.header-link-right li:not(:first-child) {
    margin-left: 12px;
  }
  .header-link-right img {
    margin-right: 5px;
    cursor: pointer;
  }
  .header-auth a {
    color: #2E3639;
  }
.count-blk {
    background: var(--secondary-color);
    display: inline-block;
    padding: 0px 8px;
    border-radius: 5px;
    margin-left: 5px;
  }
  .banner-tag {
    background: #fff;
    display: inline-block;
    padding: 3px 10px;
    border-radius: 10px;
    margin: 0 0 40px;
  }
  .homesearch {
    padding: 75px 0;
    background: #F8F8F8 url("../img/icons/subtract.png") no-repeat;
    background-position: bottom;
    background-size: cover;
  }
  
.homesearch p {
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 24px;
    font-weight: 800;
    padding-right: 85px;
  }
  .section-title {
    text-align: center;
    margin: 0 0 50px;
    text-transform: uppercase;
  }
  .section-title2 {
    text-align: center;
    margin: 0 0 40px;
    text-transform: uppercase;
  }
  .section-title p {
    font-size: 20px;
    font-weight: 500;
  }
.section-title h2{
    font-family: Poppins;
    font-size: 36px;
    font-weight: 700;
    margin: 15px 0;
  }
  .section-title2 h2{
    font-family: Poppins;
    font-size: 36px;
    font-weight: 700;
    margin: 0;
  }
  .section-title span {
    color: var(--primary-color);
  }
  .section-card {
    background: #fff;
    border-radius: 20px;
    padding: 20px;
  }
  .trust-section {
    padding: 50px 0;
    background: #F8F8F8;
  }
  .trust-content p {
    color: #6D7A80;
    margin-bottom: 30px;
  }
  .trust-section .section-title {
    margin-bottom: 30px;
  }
  .trust-section h4 {
    font-size: 20px;
    font-weight: 600;
    margin: 25px 0;
  }
  .trust-section li {
    margin-bottom: 20px;
    padding-left: 30px;
    color: #6D7A80;
    background: url(../img/icons/tick.svg) no-repeat;
    background-position: left center;
  }
  .trust-section li span {
    font-weight: 500;
    color: #2E3639;
  }
  .trust-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }
  .primary-btn {
    background: var(--secondary-color);
    font-weight: 600;
    padding: 10px 15px;
  }
  .secondary-btn {
    background: var(--primary-color);
    font-weight: 600;
    padding: 10px 15px;
    color: #fff;
  }
  .priority-section {
    padding: 50px 0;
    background: #F8F8F8;
  }
  .priority-card {
    background: #fff;
    padding: 25px;
    border-radius: 10px;
  }
  .priority-card h3 {
    font-size: 24px;
    font-weight: 700;
    margin: 10px 0 15px;
  }
  .priority-card p {
    color: #6D7A80;
    margin: 0;
  }  
.priority-img img {
    border-radius: 8px;
    border-radius: 8px;
-webkit-transition: ease all 0.5s;
-ms-transition: ease all 0.5s;
transition: ease all 0.5s;
  }
  .priority-img {
    overflow: hidden;
    -webkit-transition: ease all 0.5s;
    -ms-transition: ease all 0.5s;
    transition: ease all 0.5s;
  }
  .priority-img img:hover {
    -webkit-transition: ease all 0.5s;
    -ms-transition: ease all 0.5s;
    transition: ease all 0.5s;
    transform: scale(1.1) rotate(3deg);
    -webkit-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
  }
.mobapp-section {
    position: relative;
    padding: 50px 0;
  }
  .mobapp-subtract-top:before {
    position: absolute;
    width: 100%;
    content: "";
    height: 100%;
    top: 0;
    background: url(../img/home/mobsubtract-top.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    z-index: -1;
  }
  .mobapp-subtract-bottom:before {
    position: absolute;
    width: 100%;
    content: "";
    height: 100%;
    bottom: 0;
    background: url(../img/home/mobsubtract-top.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    z-index: -1;
    background-position: bottom;
  }
.mobapp-content p {
    color:#818181;
    font-size: 15px;
  }
  .applinks {
    margin: 20px 0 0;
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 15px;
  }
  .qrbox {
    border: 1px solid #fff;
    padding: 10px;
    border-radius: 8px;
    display: inline-block;
  }
  .applinks a {
    display: inline-block;
  }
  .appdata {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
  }
  .appdata h4 {
    font-size: 24px;
    font-weight: 700;
    margin: 10px 0;
  }
  .mobapp-section .section-title2 {
    margin-bottom: 15px;
    text-align: left;
  }  
.mobapp-content {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    padding: 50px;
  }
  
.mobapp-subtract-body {
    background: #fff;
    border-radius: 20px;
  }
  
.highlight-row {
    background: #fff;
    border-radius: 20px;
    margin: 0 0 50px;
    padding: 24px;
  }
  .highlight-box {
    padding: 25px;
    border-radius: 10px;
  }
  .highlight-bg1 {
    background: #FFFFF2;
  }
  .highlight-bg2 {
    background: #F0FFFB;
  }
  .highlight-img img {
    border-radius: 20px;
    width: 100%;
  }
  .highlight-img {
    position: relative;
    margin: 0 0 30px;
  }
  .highlight-overlay {
    position: absolute;
    bottom: 0;
    background: #fff;
    border-radius: 20px;
    margin: 30px;
    width: 300px;
    right: 0;
    padding: 30px;
  }
  .highlight-overlay img {
    width: auto;
  }
  .highlight-overlay h3 {
    font-size: 18px;
    font-weight: 600;
    color: #191919;
    margin: 0 0 20px;
  }
  .highlight-overlay p {
    color: #818181;
    font-size: 15px;
    margin: 0 0 20px;
  }
  .highlight-content p {
    font-size: 14px;
    margin: 0 0 40px;
  }
  .highlight-overlay li {
    display: flex;
  }
  .highlight-overlay h6 {
    font-size: 16px;
    font-weight: 700;
    margin-left: 10px;
  }
  .highlight-overlay li:not(:last-child) {
    margin-bottom: 10px;
  }
  .highlight-content h4 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  .highlight-content li {
    width: calc(50% - 15px);
    display: flex;
    align-items: center;
    color: #818181;
  }
  .highlight-content ul {
    display: flex;
    flex-flow: wrap;
    gap: 15px;
  }
  .highlight-content img {
    margin-right: 10px;
  }
  
.home-img-grid ul {
    display: flex;
    align-items: end;
    gap: 35px;
  }
  .home-img-grid img {
    border-radius: 35px 35px 0 0;
  }
.home-img-grid li {
    position: relative;
  }
  .home-img-grid li.active:before {
    content: "";
    position: absolute;
    width: 100%;
    height: calc(100% + 40px);
    background: var(--secondary-color);
    z-index: -1;
    top: -10px;
    right: -10px;
    border-radius: 35px;
  }
  .home-img-grid li.active::after {
    position: absolute;
    content: "";
    width: 100%;
    height: calc(100% + 40px);
    left: -10px;
    background: var(--primary-color);
    z-index: -1;
    border-radius: 35px;
    top: 15px;
  }
  .marquee-section {
    --marquee-width: 100vw;
    --offset: 20vw;
    --move-initial: calc(-25% + var(--offset));
    --move-final: calc(-50% + var(--offset));
    --move-initial-opp: calc(25% + var(--offset));
    --move-final-opp: calc(50% + var(--offset));
    --item-font-size: 10vw;
  }
.marquee-grid-one {
    background: var(--primary-color);
    position: relative;
  }
.marquee-grid-two {
    background: var(--secondary-color);
}
  .marquee-grid-one span {
    display: inline-block;
    text-shadow: 2px 0 #ffffff, -2px 0 #ffffff, 0 2px #ffffff, 0 -2px #ffffff, 1px 1px #ffffff, -1px -1px #ffffff, 1px -1px #ffffff, -1px 1px #ffffff;
    color: var(--primary-color);
    padding: 0 50px;
    position: relative;
  }
  .marquee-grid-two span {
    display: inline-block;
    text-shadow: 2px 0 #191919, -2px 0 #191919, 0 2px #191919, 0 -2px #191919, 1px 1px #191919, -1px -1px #191919, 1px -1px #191919, -1px 1px #191919;
    color: #000;
    padding: 0 50px;
    position: relative;
    color: var(--secondary-color);
  }
  .marquee-inner-grid {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--marquee-width);
    overflow: hidden;
    pointer-events: none;
    mix-blend-mode: color-burn;
  }
  .marquee-inner-list {
    width: fit-content;
    display: flex;
    position: relative;
    transform: translate3d(var(--move-initial), 0, 0);
    animation: marquee 5s linear infinite;
      animation-play-state: running;
  }
  .marquee-section {
    text-align: center;
    font-size: 64px;
    font-weight: 700;
    font-family: Poppins;
  }
  .marquee-grid-one span:after {
    content: "";
    position: absolute;
    width: 10px;
    height: calc(100% - 10px);
    border: 2px solid #fff;
    top: 5px;
    left: -5px;
    transform: skewX(-15deg);
  }
  .marquee-grid-two span:after {
    content: "";
    position: absolute;
    width: 10px;
    height: calc(100% - 10px);
    border: 2px solid #191919;
    top: 5px;
    left: -5px;
    transform: skewX(-15deg);
  }
.marquee {
	overflow: hidden;
	pointer-events: none;
    padding: 40px 0;
}
.marquee-grid-two .marquee-inner {
  animation-direction: reverse;
}
.marquee-inner {
	width: fit-content;
	display: flex;
	position: relative;
	transform: translate3d(var(--move-initial), 0, 0);
	animation: marquee 5s linear infinite;
	animation-play-state: running;
}

.menu__item-link,
.marquee span {
	white-space: nowrap;
	font-size: 64px;
	font-weight: 700;
	line-height: 1.15;
}

@keyframes marquee {
	0% {
		transform: translate3d(var(--move-initial), 0, 0);
	}

	100% {
		transform: translate3d(var(--move-final), 0, 0);
	}
}
.intro-img img {
    border-radius: 20px;
    width: 100%;
  }
  .intro-img {
    margin: 0 0 20px;
  }
  .intro-content {
    padding: 0 20px;
  }
  .intro-section {
    padding: 100px 0 50px;
  }
  .intro-data-box {
    padding: 24px;
    text-align: center;
  }
  .intro-data-box li {
    position: relative;
    display: inline-block;
    min-width: 30%;
  }
  .intro-data-box li:not(:last-child) {
    margin-right: 15px;
  }
  .intro-data-box li:last-child:after {
    display: none;
  }
  .intro-data-box h2 {
    font-size: 40px;
    font-weight: 700;
    margin: 0 0 10px;
  }
  .intro-data-box p {
    font-size: 14px;
  }
  .intro-data-box li::after {
    background: #FFEF46;
    width: 1px;
    height: 70%;
    content: "";
    position: absolute;
    right: -15px;
    top: 10px;
  }
  
.intro-desc {
    margin: 30px 0 40px;
    text-align: center;
    font-size: 14px;
  }
  .intro-content-btn {
    text-align: center;
    margin: 0 0 20px;
  }
  .intro-content-btn p {
    font-weight: 500;
    color: #0E1017;
    margin: 35px auto 0;
    max-width: 250px;
  }
  .intro-content-btn .btn {
    background: #16FFBD;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;
  }
  .intro-content-btn .btn:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #FFEF46;
    animation: pulse-border 1500ms ease-out infinite;
  }
  .intro-content-btn .btn::after {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 1px solid #FFEF46;
    animation: pulse-border 1500ms ease-out infinite;
  }
  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
  }
  
.intro-right-grid {
    height: 100%;
  }
  .intro-points-box {
    padding: 25px;
    text-align: center;
  }
  .intro-points-box h5 {
    font-size: 20px;
    font-weight: 700;
    margin: 10px 0;
  }
  .intro-points-box img {
    margin: 0 0 10px;
  }
  .intro-points-box .gr-border {
    background: red;
  }
  .intro-right-grid .gr-border {
    margin-top: 24px;
    border: 1px solid #D7E3E3;
    transition: all 0.3s;
    background: #fff;
  }
  .intro-right-grid .gr-border:hover {
    background: linear-gradient(90deg, #16FFBD 26.23%, #FFEF46 76.17%);
  }
  
.review-grid h2 {
    font-weight: 700;
    font-size: 24px;
    font-family: Poppins;
    margin: 0 0 15px;
  }
  .review-grid {
    position: relative;
    z-index: 1;
  }
  .review-grid h2 span {
    position: relative;
  }
  .review-stars {
    color: #FFAD00;
    margin: 0 0 15px;
  }
  .review-grid p {
    font-size: 20px;
    font-weight: 500;
  }
.subscriptioninfo-box {
    border-radius: 10px;
    display: flex;
    height: 100%;
  }
  .subscriptioninfo-bg1 {
    background: #FFCFE0;
  }
  .subscriptioninfo-bg2 {
    background: linear-gradient(0deg, #FFF6D2, #FFF6D2), linear-gradient(180deg, #FFCB00 0%, #FFD1E1 100%);
  }
  .subscriptioninfo-img {
    display: flex;
    align-items: end;
    height: 100%;
    justify-content: end;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
  .subscriptioninfo-img:before {
    width: 400px;
    height: 400px;
    content: "";
    position: absolute;
    background: #fff;
    border-radius: 50%;
    left: 5%;
    bottom: -50%;
    z-index: -1;
  }
  .subscriptioninfo-bg1 .subscriptioninfo-img::before {
    background: #FFF6D2;
  }
  .subscriptioninfo-bg2 .subscriptioninfo-img::before {
    background: #FFCFE0;
}

.subscriptioninfo-content {
    padding: 80px 35px 20px;
    text-align: center;
  }
  .subscriptioninfo-content h4 {
    margin: 20px 0;
    font-size: 24px;
    font-weight: 700;
  }
  .subscriptioninfo-content p {
    color: #6D7A80;
  }
  .subscriptioninfo-content .btn {
    margin-top: 15px;
  }
  
  .testimo-slider .swiper-button-prev {
    bottom: 0;
    margin-top: auto;
    left: calc(50% - 60px);    
    width: 44px;
  }
  
  .testimo-slider .swiper-button-next {
    bottom: 0;
    margin-top: auto;
    right: calc(50% - 60px);    
    width: 44px;
  }
  .testimo-slider .swiper-button-prev:before,.testimo-slider .swiper-button-next::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #16FFBD 26.23%, #FFEF46 76.17%);
    left: 0;
    top: 0;
    z-index: -2;
    border-radius: 10px;
  }
  .testimo-slider .swiper-button-prev:after,.testimo-slider .swiper-button-next::after {
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: #fff;
    left: 2px;
    z-index: -1;
    top: 2px;
    border-radius: 10px;
  }
  .testimo-slider .swiper {
    padding-bottom: 65px;
  }
  .testimo-slider .swiper-button-next::after,.testimo-slider .swiper-button-prev::after { 
    color: #000;
    border-radius: 10px;
    border: 0;
    height: 40px;
  }
.services-slider-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #F0FFFB;
    position: relative;
    border-radius: 20px;
    transition: all 0.5s;
    padding: 30px;
    text-align: center;
  }
  .services-slider-grid h5 {
    font-size: 20px;
    font-weight: 700;
    margin: 15px 0;
  }
  .services-section {
    padding: 50px 0;
  }
  .services-slider-grid li:not(:last-child) {
    margin: 0 0 15px;
  }
  .services-slider-grid li {
    font-size: 15px;
    color: #818181;
  } 
.services-slider-grid.services-slider-grid-lbl {
    background: #F8F8F8;
    border-radius: 0;
  }
  .services-slider-grid.services-slider-grid-lbl img,.services-slider-grid.services-slider-grid-lbl h5 {
    visibility: hidden;
  }
  .services-slider-grid.services-slider-grid-lbl li {
    color: #000000;
  }
.testimonial-card {
    background: #fff;
    padding: 24px;
    border-radius: 10px;
    border: 1px solid #D7E3E3;
    position: relative;
    margin-top: 43px;
  }
  .testimonial-card::before {
    content: url(../img/icons/comment.svg);
    position: absolute;
    top: -45px;
    right: 25px;
  }
  .profile-user {
    display: flex;
    margin-bottom: 20px;
  }
  .profile-user-img img {
    border-radius: 5px;
    width: 50px;
    height: 50px;
  }
  .profile-user-img {
    margin-right: 8px;
  }
  .profile-user-info h6 {
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: 600;
    color: #191919;
  }
  .profile-user-info h6 a {
    color: #191919;
  }
  .testimonial-card p {
    color: #818181;
    font-size: 15px;
  }
  .testimonial-img img {
    border-radius: 5px;
  }
  .testimonial-img {
    position: relative;
  }
  .testimonial-overlay {
    position: absolute;
    bottom: 0;
    color: #fff;
    padding: 30px;
    display: flex;
    align-items: end;
    height: 100%;
    z-index: 1;
  }
  .play-btn {
    width: 60px;
    height: 60px;
    background: #FF0059;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
    margin-left: auto;
    font-size: 32px;
  }
  .testimonial-overlay p {
    width: calc(100% - 80px);
  }
  .testimonial-overlay:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.65);
    left: 0;
    top: 0;
    border-radius: 10px;
    z-index: -1;
  }
  .testimonial-section {
    position: relative;
    padding: 100px 0;
    background: #fff;
  }
.latestnews-section {
    padding: 100px 0;
    background: #F0FFFB;
}
  .latestnews-card {
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
  }
  .latestnews-img {
    position: relative;
  }
  .latestnews-img:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 203, 0, 0.25) 0%, rgba(255, 0, 89, 0.25) 58.84%);
    top: 0;
    left: 0;
  }
  .latestnews-overlay {
    position: absolute;
    background: linear-gradient(90deg, #009900 0%, #FFA500 108.52%);
    bottom: 0;
    right: 0;
    z-index: 1;
    color: #fff;
    padding: 10px;
    font-size: 15px;
  }
  
.postcodeform-home input[type="text"] {
  width: 100%;
  height: 44px;
  border: 1px solid #CCC;
  border-radius: 10px;
  padding: 0 10px;
  margin-bottom: 25px;
}
.postcodeform-home {
  text-align: center;
  position: relative;
  z-index: 1;
}

.postcodeform-home .dropdown {
  position: absolute;
  z-index: 999;
  background: #fff;
  top: 44px;
  width: 100%;
}
  .latestnews-info h6 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .newsdate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: normal;
    font-size: 20px;
    font-weight: 700;
    line-height: 15px;
  }
  .newsday {
    font-weight: 400;
    font-size: 14px;
  }  
.latestnews-info {
    padding: 24px 24px 24px 0;
  }
  
.latestnews-date {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-align: right;
    padding: 15px 15px 80px 15px;
    background: linear-gradient(90deg, #16FFBD 0%, #FFEF46 108.52%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 15px;
  }
  .latestnews-card p {
    color: #6D7A80;
  }
  .faq-section {
    position: relative;
    padding: 0 0 100px;
  }
  .faq-subtract-top:before {
    position: absolute;
    width: 100%;
    content: "";
    height: 100%;
    top: 0;
    background: url(../img/home/faqsubtract.png);
    background-size: 100% 200px;
    background-repeat: no-repeat;
    z-index: 1
  }
  .faq-subtract-bottom:before {
    position: absolute;
    width: 100%;
    content: "";
    height: 100%;
    bottom: -1px;
    background: url(../img/home/faqsubtract-bottom.png);
    background-size: 100% 50px;
    background-repeat: no-repeat;
    z-index: 1;
    background-position: bottom;
  }
  .faq-home {
    width: 100%;
  }
.faq-content {
    position: relative;
    z-index: 2;
  }
  .faq-home .accordion-item {
    margin-bottom: 15px;
    border: 1px solid #D7E3E3;
    border-radius: 10px;
    overflow: hidden;
  }
  .faq-home .accordion-button {
    font-weight: 700;
    color: #191919;
    font-size: 18px;
    box-shadow: 0 0 0;
  }
  .faq-home .accordion-button:not(.collapsed) {
    background: #fff;
  }
  .faq-home .accordion-button::after {
    background-image: url(../img/icons/plus.png);
  }
  .faq-home .accordion-button:not(.collapsed)::after { 
    background-image: url(../img/icons/minus.png);
  }
  
.mobapp-img {
    position: relative;
  }
  .mobapp-img-overlay {
    position: absolute;
    bottom: 150px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-left: 70px;
    background: rgba(0, 0, 0, 0.67);
    padding: 40px;
    text-align: center;
    border-radius: 20px;
    backdrop-filter: blur(9px);
    z-index: 2;
  }
  .mobapp-img-overlay p {
    color: #FFCB00;
    font-weight: 600;
    font-size: 24px;
  }
  .mobapp-img-overlay h4 {
    font-weight: 700;
    color: #fff;
    font-size: 40px;
    margin-bottom: 40px;
  }
  .mobapp-img-overlay .btn {
    font-size: 14px;
  }
  .mobapp-img-overlay i {
    font-size: 12px;
    margin-left: 10px;
  }
.vertical-alignment-helper.model-std h3{
    font-size: 18px;
    color: #ff0000;
}
.custom-modal-class .vertical-alignment-helper.model-std .modal-body input[type=submit] {
    font-size: 18px;
    background: #b1524f;
}
.custom-modal-class .vertical-alignment-helper.model-std .modal-body input {
    padding: 8px;
}
.custom-modal-class .vertical-alignment-helper.model-std .modal-body h3 {
    margin: 0 0 15px;  
}
.alert-icons i {
    color: #ff0000;
    font-size: 46px;
}
.alert-icons {
    padding-bottom: 20px;
}
.carousel-inner {
    display: flex;
    justify-content: center;
    gap: 35px;
}
.section-header .section-title {
    max-width: 234px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; 
}
.theme-clr-primory {
    color: var(--secondary-color);
}
.catselect {
    background: transparent;
    border: 0;
    max-width: 100%;
  }
.hover-btn::before, .hover-btn::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.25);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
    -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.hover-btn::after {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
} 
.hover-btn:hover::before, .hover-btn:hover::after {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}
.postcodeform input[type=submit],.postcodeform .btn {
    position: relative;
}
.StripeElement {
    border: 1px solid #e1e1e1;
    box-shadow: inset 0 0px 0px rgb(0 0 0 / 0%);
    border-radius: 0px;
    padding: 15px;
    height: 45px;
    margin: 10px auto 0px auto;
    width: 100%;
}
.hover-btn {
    color: rgba(255,255,255,1);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
    border: 1px solid rgba(255,255,255,0.5);
    overflow: hidden;
}
.home_single_search.not-covered {
    background: #ffffff;
}
.home_single_search.not-covered .form-search {
    max-width: 100%;
}
.no-search .form-search {
    font-size: 16px;
    font-weight: 400;
    max-width: 600px;
    text-align: center;
}
.no-search .form-search {
    background: transparent;
    padding: 0;
    margin: 75px 0;
}
.no-search-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(237, 28, 36, 0.1);
    padding: 20px;
}
.no-search-cont i {
    font-size: 10px;
    cursor: pointer;
}
.no-search-cont p {
    color: #323F52;
    font-weight: 600;
    margin: 0;
}
.no-search-hint {
    margin: 35px 0;
    color: #0F9F1E;
    font-weight: 600;
}
.text-theme {
    color: #09b2dc !important;
}
.notcovered {
    margin: 0 auto;
    float: none;
}
#available-area #postcode-input {
    height: 60px;
  }
.no-search-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(237, 28, 36, 0.1);
    padding: 20px;
}
.no-search-cont i {
    font-size: 10px;
    cursor: pointer;
}
.no-search-cont p {
    color: #323F52;
    font-weight: 600;
    margin: 0;
}
.no-search-hint {
    margin: 35px 0;
    color: #0F9F1E;
    font-weight: 600;
}
.presentation .menuitem img {
    padding-right: 13px;
}
.position-align-center {
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
}
.text-theme {
    color: #ED1C24;
}
.no-bg-color {
    background-color: transparent;
}
.py-76 {
    padding: 76px 0px !important;
}
.logins-blk {
    border-bottom: 0px solid transparent;
    position: relative;
    top: -25px;
    margin: 0px auto -25px;
    display: flex;
    justify-content: center;
    gap: 5px;
}
.ml-14 {
    margin-left: 4px;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.form-group {
    margin-bottom: 1.25rem;
}
.form-control[type=file] {
	min-height: auto;
}
textarea.form-control {
	resize: vertical;
	height: auto;
}
.table>:not(:first-child) {
	border: 0;
}
table.table td,table.table th {
	border-color: #dee2e6;
}
.table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6;
}
.dataTables_length select {
    appearance: auto;
}

/**** Header Start ****/

.fixed {
    position: fixed;
    top: 0;
    left: 0;
    animation: smoothScroll 1s forwards;
    width: 100%;
    z-index: 99;
}
@keyframes smoothScroll {
    0% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}
.header {
    padding: 20px 0px;
    box-shadow: 0px 4px 4px rgba(201, 201, 201, 0.25);
    background: #fff;
    -webkit-transition: all 0.5s ease;
    -moz-transition: position 10s;
    -ms-transition: position 10s;
    -o-transition: position 10s;
    transition: all 0.5s ease;
}
.header-nav {
    padding: 0px;
}
.logo {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
#site_logo {
    max-height: 95px;
    max-width: 100%;
  }
ul.header-link {
    display: flex;
    height: 100%;
    align-items: center;
}
ul.header-link li {
    display: inline-block;
    padding: 0 10px;
}
.header-link li a {
    font-size: 14px;
    font-weight: 500;
    color: #2E3639;
    text-decoration: none;
    text-decoration: none;
    transition: .5s all;
    -webkit-transition: .5s all;
    -moz-transition: .5s all;
    -o-transition: .5s all;
    -ms-transition: .5s all;
    display: block;
}
.header-link li .nav-bar-link.active {
    color: var(--primary-color);
    position: relative;
  }
  .header-link li .nav-bar-link.active::after {
    position: absolute;
    content: "";
    width: 20px;
    height: 5px;
    background: var(--primary-color);
    border-radius: 0;
    left: calc(50%);
    bottom: -8px;
    transform: translateX(-50%);
  }
.header-link li a:hover {
    color: var(--secondary-color);
    text-decoration: none;
}
.header-link li .cart-btn {
    align-items: center;
    display: inline-flex;
    padding: 0;
    background-color: #fff;
    transition: 250ms;
    color: #000;
    border-radius: 50px;
    width: 48px;
    justify-content: center;
    height: 48px;
    position: relative;
}
.cart-list {
    position: relative;
}
.login-link a > .color-theme {
    color: #fff !important;
    font-weight: 800;
    text-decoration: none;
}
.home_single_search {
    width: 100%;
    position: relative;
    z-index: 1;
    background: #fff url(../img/home/banner-vbg.png) no-repeat top center;
    overflow: hidden;
}
.banner-search-wrap {
    padding: 70px 0 40px;
  }
.home_single_search h1 {
    font-size: 48px;
    line-height: 60px;
    font-weight: 700;
    color: #0E1017;
    margin: 24px 0;
    text-transform: uppercase;
    font-family: Poppins;
}
.home_single_search p {
    display: block;
    font-size: 20px;
    font-weight: 500;
}

.loademore-card {
  text-align: center;
  margin: 50px 0 0;
}
.gr-btn {
  position: relative;
  z-index: 1;
  padding: 10px 24px;
  text-transform: uppercase;
  font-weight: 500;
}
.gr-btn:after {
  position: absolute;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: #fff;
  content: "";
  left: 2px;
  z-index: -1;
  top: 2px;
  border-radius: 10px;
}
.gr-btn:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #16FFBD 26.23%, #FFEF46 76.17%);
  left: 0;
  top: 0;
  z-index: -2;
  border-radius: 10px;
}
.postcodeform {
    width: 100%;
    margin-bottom: 0px;
    display: flex;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid rgba(46, 54, 57, 0.25);
    padding: 10px;
    border-radius: 10px;
}
.postcodeform input[type=text] {
    padding: 10px 15px;
    border: 0;
    width: 100%;
    font-weight: 700;
    outline: 0;
}
.postcodeform .btn a{
    text-decoration: none;
    color: #fff;
}
.hvr-float-shadow {
    display:inline-block;
    vertical-align:middle;
    -webkit-transform:perspective(1px) translateZ(0);
    transform:perspective(1px) translateZ(0);
    box-shadow:0 0 1px transparent;
    position:relative;
    -webkit-transition-duration:.3s;
    transition-duration:.3s;
    -webkit-transition-property:transform;
    transition-property:transform
}
.hvr-float-shadow:before {
    pointer-events:none;
    position:absolute;
    z-index:-1;
    content:'';
    top:100%;
    left:5%;
    height:10px;
    width:90%;
    opacity:0;
    background:-webkit-radial-gradient(center,ellipse,rgba(0,0,0,0.35) 0%,rgba(0,0,0,0) 80%);
    background:radial-gradient(ellipse at center,rgba(0,0,0,0.35) 0%,rgba(0,0,0,0) 80%);
    -webkit-transition-duration:.3s;
    transition-duration:.3s;
    -webkit-transition-property:transform,opacity;
    transition-property:transform,opacity
}
.hvr-float-shadow:hover,
.hvr-float-shadow:focus,
.hvr-float-shadow:active {
    -webkit-transform:translateY(-5px);
    transform:translateY(-5px)
}
.hvr-float-shadow:hover:before,
.hvr-float-shadow:focus:before,
.hvr-float-shadow:active:before {
    opacity:1;
    -webkit-transform:translateY(5px);
    transform:translateY(5px)
}
div.hover-offer {
    overflow:hidden
}
div.hover-offer img {
    opacity:.95;
    -webkit-transition:opacity .35s,-webkit-transform .35s;
    transition:opacity .35s,transform .35s;
    -webkit-transform:scale3d(1,1,1);
    transform:scale3d(1,1,1);
    border-radius:15px
}
div.hover-offer figcaption::before {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(255,255,255,.5);
    content:'';
    -webkit-transition:-webkit-transform .6s;
    transition:transform .6s;
    -webkit-transform:scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0);
    transform:scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0)
}
div.hover-offer:hover figcaption::before {
    -webkit-transform:scale3d(1,1,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0);
    transform:scale3d(1,1,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0)
}
@-webkit-keyframes menu-hover {
16.65% {
    -webkit-transform:skew(-12deg);
    transform:skew(-12deg)
}
33.3% {
    -webkit-transform:skew(10deg);
    transform:skew(10deg)
}
49.95% {
    -webkit-transform:skew(-6deg);
    transform:skew(-6deg)
}
66.6% {
    -webkit-transform:skew(4deg);
    transform:skew(4deg)
}
83.25% {
    -webkit-transform:skew(-2deg);
    transform:skew(-2deg)
}
100% {
    -webkit-transform:skew(0);
    transform:skew(0)
}
}
@keyframes menu-hover {
16.65% {
    -webkit-transform:skew(-12deg);
    transform:skew(-12deg)
}
33.3% {
    -webkit-transform:skew(10deg);
    transform:skew(10deg)
}
49.95% {
    -webkit-transform:skew(-6deg);
    transform:skew(-6deg)
}
66.6% {
    -webkit-transform:skew(4deg);
    transform:skew(4deg)
}
83.25% {
    -webkit-transform:skew(-2deg);
    transform:skew(-2deg)
}
100% {
    -webkit-transform:skew(0);
    transform:skew(0)
}
}
.menu-hover {
    display:inline-block;
    vertical-align:middle;
    -webkit-transform:perspective(1px) translateZ(0);
    transform:perspective(1px) translateZ(0);
    box-shadow:0 0 1px transparent;
    -webkit-transform-origin:0 100%;
    transform-origin:0 100%
}
.menu-hover:hover,
.menu-hover:focus,
.menu-hover:active {
    -webkit-animation-name:menu-hover;
    animation-name:menu-hover;
    -webkit-animation-duration:1s;
    animation-duration:1s;
    -webkit-animation-timing-function:ease-in-out;
    animation-timing-function:ease-in-out;
    -webkit-animation-iteration-count:1;
    animation-iteration-count:1
}
input[type="hidden" i] {
    appearance: none;
    background-color: initial;
    cursor: default;
    display: none !important;
    padding: initial;
    border: initial;
}
.postcodeform input[type=submit],.postcodeform .btn {
    padding: 15px;
    font-size: 14px;
    border: 0;
    background-color: var(--secondary-color);
    flex: 0 0 auto;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    border-radius: 10px;
    color: #2E3639;
}
.home_single_search h1 span {
    color: var(--primary-color);
}
.cart-list .badge {
    background-color: var(--secondary-color);
}
.fa-bell:before {
    content: "\f0f3";
}
.link-whatsapp {
    position: fixed;
    z-index: 99;
    bottom: 20px;
    right: 15px;
}
.link-whatsapp a {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    color: #06fb06;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    font-size: 30px;
    box-shadow: rgb(0 0 0 / 30%) 0px 4px 12px;
}
.social-login .btn {
    padding: 0;
}
.social-login {
    display: flex;
    gap: 5px;
    margin: 0 0 20px;
}
.dropdown-notification .dropdown-toggle::after {
    display: none;
}
.notification-header {
    background: #00e1ff;
    color: #fff;
    padding: 10px 15px;
    display: flex !important;
}
.notification-header p {
    font-weight: 600;
    margin: 0;
    display: inline-block;
    font-size: 14px;
    line-height: 1.8;
}
ul.header-link li ul.notification-dropdown p a.header-notification {
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
}
.notification-header i {
    padding-top: 8px;
    width: 250px;
}
.notification-items {
    overflow-y: scroll;
    min-height: 0px;
    max-height: 220px;
}
.nf-dismissible {
    padding: 0px 0px 0px 5px;
    margin: 0px;
    position: relative;
    border-bottom: 1px solid #ececec;
}
.notification-content img {
    width: 40px;
    display: inline-block;
    vertical-align: top;
    padding-top: 10px;
}
.carousel-inner>.item>a>img, .carousel-inner>.item>img, .thumbnail a>img, .thumbnail>img {
    display: block;
    max-width: 100%;
    height: auto;
}
.nf-content {
    display: inline-block;
    position: relative;
    top: 10px;
    left: 10px;
    width: 80%;
    padding-bottom: 10px;
}
.nf-content p {
    margin: 0px;
    line-height: 19px;
    font-size: 13px;
}
.nf-content span {
    font-size: 12px;
    color: #aeaeae;
    line-height: 20px;
    display: block;
}
.notification-dropdown {
    width: 300px !important;
}
.notify-seeall {
    text-align: center !important;
    margin-bottom: 0px;
}
.alert-dismissible {
    padding-right: 0 !important;
}
.section-mobnotification .nf-dismissible {
    padding: 0px 35px 10px 10px;
    position: relative;
    border-bottom: 1px solid #ececec;
    background: #fff;
    margin: 10px 0px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.08);
}
.header-link .dropdown-menu {
    padding: 0;
}
.presentations {
    display: inline-block;
    padding: 0px 0px 0px 0px !important;
}
.fa.pull-right{
    margin-left: 125px !important;
}
#quickordermodal .modal-header h3,#quickordermodal2 .modal-header h3 {
    font-weight: 600;
    font-size: 16px;
}
#quickordermodal .modal-body input[type=button],#quickordermodal2 .modal-body input[type=button] {
    width: 100%;
    border: 1px solid #ddd;
    padding: 18px;
    border-radius: 5px;
}
#quickordermodal .modal-body input[type=button],#quickordermodal2 .modal-body input[type=button] {
    width: 100%;
    margin-top: 25px;
    background: var(--primary-color);
    color: #fff;
    font-size: 16px;
    border: none;
    transition: all 300ms linear;
}
#cd-shadow-layer.is-visible {
    display: block;
    -webkit-animation: cd-fade-in .3s;
    -moz-animation: cd-fade-in .3s;
    animation: cd-fade-in .3s;
}
#cd-shadow-layer {
    position: fixed;
    min-height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.77);
    cursor: pointer;
    z-index: 100;
    display: none;
}
#cd-cart.speed-in {
    right: 0;
}
#cd-cart {
    right: -100%;
    background: #fff;
    -webkit-transition: right .3s;
    -moz-transition: right .3s;
    transition: right .3s;
}
#main-nav{
    position: fixed;
    top: 0;
    height: 100%;
    width: 260px;
    padding-top: 23px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-shadow: 0 0 20px rgba(0,0,0,.2);
    z-index: 1000;
}
#cd-cart {
    position: fixed;
    top: 0;
    height: 100%;
    width: 260px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-shadow: 0 0 20px rgba(0,0,0,.2);
    z-index: 1000;
}
.car-sum-cat-title {
    padding: 15px 0;
    font-weight: 600;
    color: #323F52;
  }
  .cart-list-style {
    color: #474747;
    margin: 0 0 10px;
  }
  .cart-list-style:last-child {
    margin: 0;
  }
  .track-order,
button.track-order {
 background:#0c8de1;
 position:relative;
 text-align:right
}
.track-order {
 border:0;
 border-radius:50px;
 color:#000
}
.current-status,
.vprogress .bar.done,
.vprogress .circle.done {
 background:#0e88d5
}
.vprogress {
 max-width:90%;
 margin:5px 20px 30px;
 text-align:center
}
.vprogress .circle,
.vprogress .circle .notdone {
 display:block;
 margin-bottom:0;
 background:#0e88d5;
 width:15px;
 height:15px;
 border-radius:50%
}
.vprogress .bar {
 display:block;
 width:3px;
 height:52px;
 top:0;
 margin-left:6px;
 margin-right:-5px;
 border-left:none;
 border-right:none;
 border-radius:0
}
.vprogress .circle .label {
 display:inline-block;
 width:32px;
 height:32px;
 line-height:32px;
 border-radius:32px;
 margin-top:3px;
 color:#b5b5ba;
 font-size:17px
}
.vprogress .circle .progress-title {
 color:#383838;
 font-size:13px;
 line-height:30px;
 margin-left:45px;
 position:relative;
 top:-45px;
 max-width:400px;
 text-align:left;
 min-width:350px;
 width:100%
}
.vprogress .circle .progress-title p {
 margin-bottom:7px;
 line-height:18px
}
.vprogress .circle .progress-title span {
 color:#aeaeae;
 font-size:13px;
 display:block;
 line-height:18px
}
.vprogress .bar.notdone,
.vprogress .circle.notdone {
 background:#ececec
}
.vprogress .bar.active {
 background:linear-gradient(to right,#eee 40%,#fff 60%)
}
.vprogress .circle.done .title {
 color:#444
}
.vprogress .circle.active .label {
 color:#fff;
 background:#0c95be;
 box-shadow:inset 0 0 2px rgba(0,0,0,.2)
}
.vprogress .circle.active .title {
 color:#0c95be
}
.current-status {
 display:block;
 width:22px;
 height:22px;
 border-radius:50%;
 cursor:pointer;
 box-shadow:0 0 0 rgba(204,169,44,.4);
 animation:1.5s infinite current-status
}
.current-status:hover {
 animation:none
}
.order-tag {
    background: var(--primary-color);
    color: #fff;
    border-radius: 3px;
    font-weight: 600;
    padding: 10px;
  }

@-webkit-keyframes current-status {
 0% {
  box-shadow:0 0 0 0 rgba(0,0,0,.2)
 }
 100% {
  box-shadow:0 0 0 15px transparent
 }
}
@media only screen and (min-width: 768px) {
    #main-nav, #cd-cart {
      width: 350px;
    }
  }
.cart-header {
    border-bottom: 1px solid #eee !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    background: var(--primary-color);
}
.cart-header h4 i {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
  background: var(--secondary-color);
  height: 35px;
  width: 35px;
  color: #0E1017;
  font-size: 14px;
  }
  #cart-items-container {
    padding: 0 15px;
  }
#cd-cart h4 {
    margin-bottom: 0;
font-weight: 600;
font-size: 18px;
display: flex;
align-items: center;
color: #fff;
}
#cd-cart .cd-cart-items {
    padding: 0;
    border-bottom: 1px solid #e0e6ef;
}
#cd-cart .cd-cart-items li.main-title {
    list-style: none;
    font-weight: bold;
}
#cd-cart .cd-cart-items li.cart-list-style {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#cd-cart .cd-cart-items li {
    padding: 0.5em 1em;
}
#cd-cart .cd-item-remove {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: url("") no-repeat center center;
}
#cd-cart .cd-cart-total {
    padding-top: 1em;
    padding-bottom: 1em;
}
#cd-cart .checkout-btn {
    display: block;
    height: 60px;
    background: var(--primary-color);
    color: #FFF;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    width: 100%;
}
.loading {
    z-index: 999999;
    margin: 0 auto;
    bottom: 0;
    right: 0;
    background-color: rgba(255,255,255,.9);
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
  }
  .error-box {
    max-width: 480px;
    text-align: center;
    width: 100%;
    margin: 75px auto;
    padding: 1.875rem 0;
  }
  .error-box h1 {
    font-size: 10em;
  }
  .error-box h3 {
    font-size: 26px;
  }
  .error-box p {
    margin-bottom: 25px;
    font-size: 20px;
  }
  .error-box .btn {
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px;
  }
.cat-item-close-btn {
    color: #f00;
    background: transparent;
    border: 1px solid #f00;
    border-radius: 50%;
    font-size: 8px;
  }
  .cat-item-close-btn:hover {
    background: #f00;
    color: #fff;
  }
#cd-cart .cd-go-to-cart {
    text-align: center;
    margin: 1em 0;
}
#cd-cart .cd-go-to-cart a {
    border-bottom: 4px solid var(--secondary-color);
    font-size: 16px;
    font-weight: 600;
    color: #2E3639;
}
.cart-header i {
    cursor: pointer;    
    color: #fff;
}
.postcodeform .dropdown{
    position: absolute;
    margin-top: 62px;
    background: #fff;
    width: 29rem;
    border: 1px solid #f3f3f3;
    box-shadow: 0px 2px 3px rgba(0,0,0,0.2);
    border-radius: 5px;
    z-index: 99;
}
.postcodeform-modal.postcodeform .dropdown, .notcovered .postcodeform .dropdown{
    margin-top: 55px;
}
.postcodeform .dropdown:empty {
    border: none !important;
}
.dropdown-row {
    cursor: pointer;
    text-align: start;
    padding: 10px 15px;
    font-weight: 600;
    font-size: 18px;
    position: relative;
}
.dropdown-row i {
    margin: 0 10px 0 0;
    color: #a7c1d1;
  }
.dropdown-row:not(:last-child) {
    border-bottom: 1px solid #dce0e3;
}
.dropdown-row:hover{
    background-color: var(--primary-color);
    color: #fff;
}
.dropdown-row:hover i {
    color: #fff;
}
.list-breadcums {
    padding: 15px 0;
}
.list-breadcums ul {
    padding: 0;
    margin: 6px 0 0;
}
.list-breadcums ul li:first-child {
    padding-left: 0;
}
.list-breadcums ul li {
    display: inline-block;
    font-size: 13px;
    padding-right: 5px;
    text-transform: uppercase;
    font-weight: 600;
}
.list-breadcums ul li a {
    font-size: 13px;
    color: #681986;
    font-weight: 600;
    text-decoration: none;
}
#accordion {
    margin-top: 0;
    margin-bottom: 0;
}
.swiper-container {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}
.mb-30 {
    margin-bottom: 30px;
}
.category-container {
    background: 0 0;
    border: 0;
    box-shadow: 0 1px 1px transparent;
}
.order_list {
    background: #fff1d7;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.user-img {
    display: block;
    position: relative;
    margin-bottom: 10px;
    text-align: center;
}
.user-img img {
    text-align: center;
    width: 80px;
    margin: 0 auto;
    transition: 0.5s all;
    box-shadow: 0px 0px 0px 0px #aaabab;
    border-radius: 50%;
    height: 80px;
    padding: 3px;
}
.swiper-button-next::after, .swiper-button-prev::after {
    font-size: 13px !important;
    border: 1px solid;
    border-radius: 50%;
    width: 50px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #323F52;
}
.pro-det {
    overflow: hidden;
    flex: 1 0 0;
  }
  .pro-det h5 {
    margin: 0;
    font-size: 16px;
  }
#heading-1 a{
    text-decoration: none;
}
.list-explore p {
    bottom: 14px;
    left: 0;
    right: 0;
    line-height: 20px;
    font-size: 14px;
    font-weight: 600;
    transition: .5s all;
    color: var(--secondary-color);
    width: 100%;
    padding: 0;
    text-align: center;
}
.swiper-container .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000;
}
.list-collapse {
    margin-top: 0;
    margin-bottom: 25px;
}
.collapse-body {
    padding: 0;
}
.list_li {
    display: flex;
    width: 100%;
    margin-bottom: 25px;
}
.product {
    background-color: #fff;
    width: 100%;
    border-radius: 6px;
    position: relative;
    padding: 10px;
    display: flex;
    align-items: center;
}
.product .pro-det a {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    word-break: break-word;
    flex-direction: column;
    background: #fff;
    font-size: 14px;
    word-wrap: break-word;
    text-decoration: none;
}
.product .pro-det a img {
    width: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
}
.product .pro-det a h5 {
    font-size: 20px;
    padding-top: 0;
    margin-bottom: 15px;
    max-width: 100%;
    color: #4A4A4A;
    font-weight: 600;
}
#list-cart {
    height: 100%;
    display: flex;
  }
.product .pro-det a p.product-option {
    color: #777;
    font-size: 13px;
    margin-bottom: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.product .btn-group {
    margin-bottom: 20px;
}
.btn-group, .btn-group-vertical {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}
.btn-group>.btn:first-child {
    margin-left: 0;
}
.product .option-btn {
    border: 0;
    padding: 0;
    background-color: #fff;
    box-shadow: unset;
    font-size: 11px;
}
.product .option-btn {
    border: 0;
    padding: 0;
    background-color: #fff;
    box-shadow: unset;
    font-size: 11px;
}
.btn .caret {
    margin-left: 0;
}
.price-amount {
  text-align: center;
  flex: 1 0 0;
}
.amount {
    font-size: 22px;
    font-weight: 600;
}
.price-amount .listcloth-rht {
    margin: 0;
    display: flex;
    align-items: center;
}
.listcloth-rht span {
    display: inline-block;
}
.listcloth-rht span button.quantity-left-minus, .listcloth-rht span button.quantity-right-plus {
    font-weight: 600;
    border-radius: 5px;
    padding: 3px 6px 5px;
    font-size: 12px;
    display: inline-block;
    background: #fff;
    margin-left: 0;
    width: 38px;
    text-align: center;
    height: 38px;
    color: #0E1017;
    border: 1px solid #0E1017;
}
.listcloth-rht span input {
    width: 40px;
    border: 0;
    box-shadow: inset 0 0 0 transparent;
    text-align: center;
    background: #fff!important;
}
.theiaStickySidebar {    
    position: relative;
    overflow: visible;
    box-sizing: border-box;
    min-height: 1px;
    
}
.skiptoquickorder {
    box-shadow: 0px 4px 24px rgba(228, 228, 228, 0.25);
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
}
.quick-order-btn a {
    background: #fff200;
    -webkit-box-shadow: 0 1px 0 rgba(255,255,255,.4) inset;
    -moz-box-shadow: 0 1px 0 rgba(255,255,255,.4) inset;
    box-shadow: 0 1px 0 rgba(255,255,255,.4) inset;
    color: #000;
    font-size: 18px;
    padding: 12px 15px;
    border-radius: 25px;
}
.quick-order-btn button {
    background: var(--secondary-color);
    -webkit-box-shadow: 0 1px 0 rgba(255,255,255,.4) inset;
    -moz-box-shadow: 0 1px 0 rgba(255,255,255,.4) inset;
    box-shadow: 0 1px 0 rgba(255,255,255,.4) inset;
    color: #fff;
    font-size: 18px;
    padding: 12px 15px;
    border-radius: 25px;
}
.quick-order-btn button:hover {
    background: var(--primary-color);
    color: #fff;
}
.skiptoquickorder p i {
    color: #323F52;
    margin-right: 5px;
}
.price-details {
    box-shadow: 0px 4px 24px rgba(228, 228, 228, 0.25);
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    background: #fff;
    padding: 15px;
    margin-bottom: 20px;
    display: block;
    float: left;
    width: 100%;
}

.price-header {
    background: rgba(222, 222, 222, 0.17);
    padding: 25px;
    margin: 0 0 10px;
}
.price-header h2 {
    margin: 0;
    font-size: 28px;
    font-weight: 600;
    color: var(--secondary-color);
}
.price-footer-notes {
    color: #0F9F1E;
    margin: 25px 0 0;
}
.quick-order-btn {
    margin: 0 0 15px;
  }
.cs-option-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 10px;
  }
  .cs-summary-title h4 {
    border-bottom: 1px solid #F3F3F3;
    font-size: 16px;
    font-weight: 700;
    color: #1B1B1B;
    padding: 10px 0;
    margin: 0 0 15px;
  }
  .cs-option-price {
    padding: 0 10px 10px;
  }
  .cs-option-right {
    font-weight: 700;
  }
  .cs-option-amt {
    background: rgba(222, 222, 222, 0.17);
    padding: 20px 10px;
  }
  .cs-option-amt-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 15px;
  }
  .cs-option-amt-right {
    font-weight: 700;
  }
  .cs-option-amt-list.cs-option-amt-listbg {
    background: #fff;
    font-weight: 700;
    padding: 15px 5px;
  }
.option-price {
    border-bottom: 1px dashed #e1e1e1;
    padding-bottom: 20px;
}
.vertical-align {
    align-items: center;
    display: flex;
}
.option-price span.summary-title {
    margin-top: 0;
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
}
.option-body {
    color: #777;
    font-size: 12px;
    line-height: 1.8;
}
.addedprice, .option-price span {
    margin: 0;
}
.option-body span.option-total {
    margin: 0;
}
.listcloth-rht {
  flex: 1 0 0;
  text-align: right;
}
.payable-price.payable-price-cartsum {
    background: #F8F8F8;
    padding: 15px;
    margin: 10px 0 15px;
  }
  .cartsum-right {
    display: flex;
    align-items: center;
  }
  .cartsum-left {
    max-width: calc(100% - 100px);
    padding-right: 10px;
  }
.payable-price {
    font-weight: 600;
    font-size: 16px;
    margin-top: 12px;
    padding: 1em 0;
}
.payable-price span.option-total {
    float: right;
}
.price-footer {
    margin: 20px 0 8px;
}
.price-footer button.continue-btn {
    width: 100%;
    border-radius: 5px;
}
.price-footer button.continue-btn {
    background: var(--primary-color);
    border: 0;
    color: #fff;
    height: 50px;
    font-weight: 600;
    font-size: 16px;
}
.custom-myprofile a {
  color: var(--bs-black-rgb);
}
.price-footer button.continue-btn a{
    text-decoration: none;
    color: #fff;
}
.swiper-slide:nth-child(odd) .order_list .user-img img {
    border: 4px solid var(--primary-color);
}
.swiper-slide:nth-child(even) .order_list .user-img img {
    border: 4px solid var(--secondary-color);
}
.section-orderlist {
    background-color: #fff;
    min-height: 100%;
}
.glyphicon-minus:before {
    content: "\2212";
}
.glyphicon-plus:before {
    content: "\002b";
}
.section-checkout {
    background-color: #fff;
}
.list-checkout {
    margin-top: 20px !important;
    margin-bottom: 20px;
}
.pickupsteps .pickupaddress-title {
    padding: 18px 15px;
    position: relative;
}
.media-left, .media>.float-start {
    padding-right: 10px;
}
.media-object {
    display: block;
}
.pickupaddress-title h4 {
    display: inline-block;
    margin-bottom: 0;
    line-height: 28px;
    font-size: 15px;
    text-transform: uppercase;
    color: var(--secondary-color);
}
.pickupaddress-title span {
    display: inline-block;
    text-align: center;
}
.continue-tick svg {
    vertical-align: top;
    width: 30px;
    height: 20px;
}
.add-address-form {
    padding: 23px;
}
.pickupsteps .pstepsinner .btn-theme {
    margin-bottom: 0px;
    text-transform: uppercase;
}
 #save-address-continue-btn{
    background: var(--secondary-color);
    color: #fff;
    width: 100%;
}
#cancel-new-address-btn {
    border: 1px solid #ED1C24;
    color: #ED1C24;
    background: transparent;
    width: 100%;
    text-transform: uppercase;
}
.pickupsteps .editbtn {
    display: block;
    padding: 8px 18px;
    color: #323F52;
    border: 1px solid #323F52;
    position: absolute;
    right: 20px;
    top: 14px;
    font-size: 14px;
}
.pickupsteps .editbtn .fa {
    margin-right: 5px;
}
.fa-pencil:before {
    content: "\f040";
}
.pickupsteps .pstepsinner {
    padding: 20px;
    border-top: 1px solid #F3F3F3;
}
.pickupsteps .pstepsinner h3.h3steps {
    font-size: 16px;
    padding: 0;
    font-weight: 700;
    color: var(--secondary-color);
}
.datePicker-select{
    padding: 15px;
    border: 1px solid #E6E6E6;
    width: 100%;
    height: 56px;
}
.Pickup-date {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
}
.address-form {
    border: 1px solid #e1e1e1;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, .0);
    border-radius: 0px;
    height: 45px;
    margin: 0 auto;
    width: 100%;
}
.formgrp-custom {
    position: relative;
    margin-bottom: 15px;
    text-align: center;
    width: 50%;
}
.formgrp-custom span.input-validation, .formgrp-custom span.mdbselect, .validation-tick {
    display: inline-block;
    position: absolute;
    top: 11px;
    right: 10px;
    z-index: 9;
}
select.mdb-select {
    width: 100%;
    height: 56px;
    padding: 15px;
    border: 1px solid #E6E6E6;
    font-size: 13px;
    border-radius: 0px;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, .075);
    background: none;
}
.address-blk-card {
    padding: 15px;
    border: 1px solid #dedede;
    border-radius: 6px;
    word-wrap: break-word;
}
.container-blk {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.container-blk input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.container-blk input:checked~.checkmark {
    background-color: transparent;
    background:#ed1c24;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    border: 1px solid #333;
    border-radius: 50%;
}
.container-blk .checkmark:after {
    top: 2px;
    left: 2px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #ed1c24 !important;
    padding: 5px;
}
.address-blk-card {
    padding: 15px;
    border: 1px solid #EFEFEF;
    border-radius: 2px;
    word-wrap: break-word;
}
.address-blk-card p {
    font-weight: 600;
    color: #474747;
    font-size: 14px;
}
#add_new_adrs_btn i {
    display: block;
}
#add_new_adrs_btn {
    font-size: 14px;
    color: #474747;
    font-weight: 600;
}
.pickupsteps .pstepsinner .btn-theme {
    margin-bottom: 0;
    text-transform: uppercase;
}
.text-address{
    width: 40% !important;
}
#addresscontinuebtn{
    background: var(--primary-color);
    margin-top: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border-radius: 2px;
}
.payment_option {
    border: none;
    padding: 0px 0px 5px 0px;
}
.payment-title {
    background: #f9f9f9;
    padding: 20px;
    overflow: hidden;
}
.amt-payable {
    box-shadow: 0px 4px 24px rgba(228, 228, 228, 0.25);
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    background: #fff;
    padding: 23px;    
    margin-bottom: 20px;
    display: block;
    float: left;
    width: 100%;    
}
.font-summary-title {
    font-size: 18px;
    font-weight: 600;
    margin: 0px;
}
.mb-14 {
    margin-bottom: 14px;
}
.text-theme {
    color: #ED1C24;
}
.amt-payable .amtpayable-title {
    display: inline-block;
    font-weight: 600;
    font-size: 16px;
}
.amt-payable span.amtpayable-price {
    text-align: right;
    float: right;
    font-weight: 600;
}
.coupon-code {
    margin-bottom: 15px;
}
.btn-lg-seconday {
    background: var(--primary-color);
    border-radius: 2px;
    color: #fff;
    height: 60px;
}
.pay-alert {
    background: rgba(237, 28, 36, 0.03);
    padding: 25px;
    margin: 20px 0 0;
    text-align: justify;
}
#applyCoupon {    
    background: var(--secondary-color);
    border: 1px solid #E6E6E6;
    border-radius: 2px;
    color: #fff;
}
.section-signin {
    background: #f0f0f0;
    min-height: 100%;
}
.col-centered {
    float: none;
    margin: 0 auto;
}
.how-work-wrapper .work-item:last-child .work-inner .work-img::after,
.work-item .work-inner .work-img::after,
.work-item:last-child:after {
 display:none
}
.how-work-section {
    padding: 60px 0;
  }
  .sub-heading {
    font-size: 20px;
    color: #000;
    margin-bottom: 40px;
    font-weight: 700;
  }
  .howitbtn {
    background: var(--secondary-color);
    color: #fff;
    margin: 50px 0 0;
    padding: 15px 30px;
    font-weight: 600;
    font-size: 18px;
  }
  .how-work-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 20px;
  }
  .work-item {
    width: 25%;
    position: relative;
  }
  .work-item .work-inner .work-img {
    height: 160px;
    text-align: center;
    margin-bottom: 25px;
    padding: 15px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .work-inner {
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    padding: 20px 20px 35px;
  }
  /* .work-item .work-inner .work-img::before {
    border: 10px solid #fff;
      border-top-color: rgb(255, 255, 255);
      border-right-color: rgb(255, 255, 255);
      border-bottom-color: rgb(255, 255, 255);
      border-left-color: rgb(255, 255, 255);
    border-color: transparent transparent #fff #fff;
    box-shadow: -2px 2px 2px -1px rgba(0,0,0,.1);
    content: "";
    left: 50%;
    position: absolute;
    bottom: -10px;
    -webkit-transform: translateX(-50%) rotate(-45deg);
    transform: translateX(-50%) rotate(-45deg);
    -webkit-transition: .3s;
    transition: .3s;
  } */
  .work-item .work-inner .work-img img {
    width: 75px;
    height: auto;
  }
  .work-item .work-inner .work-img::after {
    position: absolute;
    content: "";
  }
  .work-item .work-inner .work-img span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    margin-bottom: 25px;
    color: #323f52; 
  }
  .work-item .work-inner .work-img::after {
    position: absolute;
    content: "";
  }
  .work-item .work-inner .work-content {
    text-align: center;
  }
  .work-item .work-inner .work-content h6 {
    margin-bottom: 15px;
    color: #000;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    transition: .3s;
    font-size: 22px !important;
    font-weight: 700;
  }
  .work-item .work-inner .work-content p {
    margin-bottom: 0;
    font-size: 16px !important;
    color: #232323;
    font-weight: 500;
    line-height: 26px;
  }
  /* .work-item::after {
    display: block;
    z-index: 1;
    position: absolute;
    top: 77px;
    left: 118%;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #fd6b1c;
    content: '';
    transform: translateX(-118%);
  } */
.login-wrapper {
    display: flex;
    flex-flow: wrap;
    height: 100%;
    background: #fff;
    box-shadow: 0px 1px 6px rgba(0,0,0,0.1);
    border-radius: 5px;
  }
  .login-offer-grid {
    width: 50%;
    display: flex;
    background: var(--primary-color) url(../img/offer-bg.png);
    background-position: bottom;
    background-size: cover;
    padding: 30px;
  }
  .login-grid {
    width: 50%;
  }
  .login-offer-grid h2 {
    text-align: center;
    margin: 45px 0 0;
  }
  .login-offer-grid h2 span {
    font-weight: 800;
  }
.login-nav input[type="radio"] {
    display: none;
  }
  .login-nav {
    margin: 0 0 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-nav a {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    display: block;
    padding: 10px;
    color: var(--secondary-color);
  }
  .login-nav .active a,.login-nav .active a:hover {
    border-bottom: 3px solid var(--primary-color);
    background: transparent;
    color: var(--primary-color);
  }
  .login-nav a:hover {
    background: #f2f2f2;
  }
  .login-nav li {
    flex: 1 0 0;
    text-align: center;
  }
  .signin-conent-wrapper {
    padding: 10px 25px 25px;
  }
.signin {
    background: #fff;
    padding-bottom: 1px;
    margin: 25px 0px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.08);
}
.signin-header {
    text-align: center;
    margin: auto;
    padding: 15px 0px 45px 0px;
    background-repeat: no-repeat;
}
.signin-header img {
    text-align: center;
    margin: 0 auto;
}
.logins-blk {
    border-bottom: 0px solid transparent !important;
    position: relative;
    top: -25px !important;
    margin: 0px auto -25px !important;
}
.logins-blk li {
    background: #ffffff;
    
}
.logins-blk li.active a {
    background: #f0f0f0 !important;
}
.logins-blk li a{
    text-decoration: none;
    color: #555;
}
.logins-blk li a:hover{
    background: #f0f0f0 !important;
}
.logins-blk a {
    padding: 14px 48px !important;
    border: 0px solid transparent !important;
    margin: 0px !important;
    display: block;
}
.signin-conent {
    max-width: 80%;
    margin: 0 auto 32px;
    position: relative;
    border-radius: 3px;
    background: #f0f0f0;
    padding: 20px 25px 20px 25px;
}
.signin-conent .form-group label {
    font-weight: 500;
    font-size: 13px;
}
button.account-btn {
    background: var(--secondary-color);
    border: 0px;
    width: 100%;
    padding: 10px 0px;
    color: #fff;
    font-weight: 600;
}
.Forgot-password{
    text-decoration: none;
    color: #383838;
}
.register-link a, .login-link a {
    color: #ED1C24;
    text-decoration: none;
}
.input-group-addon{
    padding: 6px 20px;
    background: #eee;
    border: 1px solid #ccc;
    border-radius: 2px;
}
.login_popup_toggle{
    text-decoration: none;
}
.section-thankyou {
    background: #f0f0f0;
    /* background-image: url("../img/thank-you.png"); */
    background-position: bottom left;
    background-repeat: no-repeat;
    height: 100%;
    padding: 60px 0;
    background-position: calc(0px - 5%) 200px;
    position: relative;
}
.thankyou-left, .thankyou-header img {
    text-align: center;
    margin: 0 auto;
}
.thankyou-header h1 {
    color: #0d8ada;
    padding: 15px 0px 5px 0px;
    font-size: 32px;
    margin: 0px;
}
.order-id {
    background: #fff;
    max-width: 50%;
    margin: 0 auto;
    padding: 15px;
    border-left: 2px solid #0d8ada;
}
.order-id p {
    margin: 0px;
    margin: 0px;
    font-weight: 600;
    font-size: 16px;
}
.thankyou-footer {
    max-width: 50%;
    margin: 0 auto;
}
.thankyou-footer span {
    display: block;
    margin-top: 10px;
}
button.backto-home {
    border: 0px;
    padding: 10px 20px 10px 20px;
    text-align: right;
    border-radius: 50px;
    position: relative;
    font-size: 13px;
    margin-top: 15px;
}
button.track-order {
    background: #0c8de1;
    border: 0px;
    padding: 10px 20px 10px 20px;
    text-align: right;
    border-radius: 50px;
    position: relative;
    font-size: 13px;
    color: #fff;
    margin-top: 15px;
}
.thankyou-right {
    background: #fff;
    padding: 20px;
    clear: both;
}
.ordersum_header h2 {
    font-size: 18px;
    text-transform: uppercase;
    border-bottom: 1px dashed #c0c0c0;
    padding-bottom: 18px;
    font-weight: 600;
}
.ordersum-mens h3 {
    font-size: 15px;
    margin: 15px 0px 10px 0px;
    font-weight: 600;
}
.ordersum-mens img {
    display: inline-block;
    margin-right: 10px;
    width: 40px;
    border-radius: 50%;
}
.sumlist {
    font-size: 13px;
    margin: 5px 0px;
}
.sumlist span small {
    color: #999;
    font-size: 13px;
    padding-left: 10px;
}
.sumlist span.sum-qty {
    float: right;
}
.ordersum-footer {
    background: #fff;
    padding: 20px;
    margin-top: 10px;
}
.sumlist {
    font-size: 13px;
    margin: 5px 0px;
}
.amt-payable span {
    font-size: 16px;
    font-weight: 600;
}
.swal-modal {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    position: static;
    top: 32%;
    left: 27%;
    width: 478px;
    pointer-events: none;
    background-color: #fff;
    text-align: center;
    border-radius: 5px;
    margin: 20px auto;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}
.swal-overlay--show-modal .swal-modal {
    opacity: 1;
    pointer-events: auto;
    box-sizing: border-box;
    -webkit-animation: showSweetAlert .3s;
    animation: showSweetAlert .3s;
    will-change: transform;
}
.swal-icon:first-child {
    margin-top: 32px;
}
.swal-icon {
    width: 80px;
    height: 80px;
    border-width: 4px;
    border-style: solid;
    border-radius: 50%;
    padding: 0;
    position: relative;
    box-sizing: content-box;
    margin: 20px auto;
}
.swal-icon--success:before {
    border-radius: 120px 0 0 120px;
    top: -7px;
    left: -33px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 60px 60px;
    transform-origin: 60px 60px;
}
.swal-icon--success__line--long {
    width: 47px;
    right: 8px;
    top: 38px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: animateSuccessLong .75s;
    animation: animateSuccessLong .75s;
}
.swal-icon--success__line {
    height: 5px;
    background-color: #a5dc86;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 2;
}
.swal-icon--success__line--tip {
    width: 25px;
    left: 14px;
    top: 46px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-animation: animateSuccessTip .75s;
    animation: animateSuccessTip .75s;
}
.swal-icon--success__ring {
    width: 80px;
    height: 80px;
    border: 4px solid hsla(98,55%,69%,.2);
    border-radius: 50%;
    box-sizing: content-box;
    position: absolute;
    left: -4px;
    top: -4px;
    z-index: 2;
}
.swal-icon--success__hide-corners {
    width: 5px;
    height: 90px;
    background-color: #fff;
    padding: 1px;
    position: absolute;
    left: 28px;
    top: 8px;
    z-index: 1;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.swal-icon--success {
    border-color: #a5dc86;
}
.swal-title {
    font-weight: 600;
    text-transform: none;
    position: relative;
    display: block;
    padding: 13px 16px;
    font-size: 27px;
    line-height: normal;
    text-align: center;
    margin-bottom: 0;
    font-size: 18px;
}
.swal-title:not(:first-child) {
    padding-bottom: 0;
}
.swal-title:not(:last-child) {
    margin-bottom: 13px;
}
.swal-text {
    font-size: 16px;
    position: relative;
    float: none;
    line-height: normal;
    vertical-align: top;
    text-align: left;
    display: inline-block;
    margin: 0;
    padding: 0 10px;
    font-weight: 400;
    color: rgba(0,0,0,.64);
    max-width: calc(100% - 20px);
    overflow-wrap: break-word;
    box-sizing: border-box;
    font-size: 14px;
}
.swal-footer {
    text-align: center;
    padding-top: 13px;
    margin-top: 13px;
    padding: 13px 16px;
    border-radius: inherit;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.swal-button {
    background-color: #323F52;
    color: #fff;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 24px;
    margin: 0;
    cursor: pointer;
}
.swal-button-container {
    margin: 5px;
    display: inline-block;
    position: relative;
}
.StripeElement {
    border: 1px solid #e1e1e1;
    box-shadow: inset 0 0px 0px rgb(0 0 0 / 0%);
    border-radius: 0px;
    padding: 15px;
    height: 45px;
    margin: 0 auto;
    width: 100%;
}
.card-debit, .savedcards {
    margin: 10px 0px 0px 0px;
}

/**** Header End ****/

/**** About Start ****/

.aboutsec {
    padding: 60px 0;
    background: url("../img/about-section/about-bg.png");
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
}
.aboutsec h2 {
    margin-bottom: 20px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 22px;
}
.aboutsec .section-title h1 {
    color: #fff;
    margin-bottom: 20px;
    text-align: left;
}
.aboutsec .section-line span {
    margin-left: 54px;
}

.abt-cont-desc {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .abt-cont-desc p {
    color: #363636;
  }
  .abt-cont-desc h3 {
    color: #656565;
    font-size: 36px;
    font-weight: 700;
    margin: 15px 0 35px;
  }
  .abt-cont-desc h4 {
    color: #323F52;
    font-size: 23px;
    font-weight: 600;
  }
  .abt-cont-img {
    margin: 50px 0;
  }
  
.about-step-desc {
    text-align: center;
    box-shadow: 0px 10px 10px #F5F5F5;
    border-radius: 10px;
    border: 1px solid rgba(56, 162, 232, 0.1);
    background: #fff;
    padding: 30px 15px;
    display: flex;
    height: 100%;
  }
  .about-step-desc p {
    color: #353535;
    font-size: 14px;
    margin: 0;
  }
  .about-step-desc h4 {
    margin: 20px 0 10px;
    font-size: 24px;
  }
  .about-footer-sec {
    background: #fef6f6;
    margin: 75px auto;
  }
.section-line span {
    display: block;
    height: 2px;
    width: 13px;
    background: transparent;
    margin: 6px auto 0;
    position: relative;
    border-radius: 30px;
}
.section-line .first-line::before {
    content: '';
    position: absolute;
    right: 13px;
    top: 0;
    height: 2px;
    width: 50px;
    border-radius: 17px;
    background: #fff;
}
.section-line .first-line::after {
    content: '';
    position: absolute;
    left: 13px;
    top: 0;
    height: 2px;
    width: 50px;
    border-radius: 17px;
    background: #fff;
}
.section-line.small-line span {
    width: 11px;
}
.aboutsec .section-line span {
    margin-left: 54px;
}
.section-line.small-line .second-line::before {
    right: 11px;
    top: 0;
    height: 2px;
    width: 26px;
}
.section-line.small-line .second-line::after {
    left: 11px;
    top: 0;
    height: 2px;
    width: 26px;
}
.aboutsec p {
    margin: 20px 0 25px;
    font-size: 16px;
}
.about-icon {
    margin-top: 35px;
}
.about-icon .media {
    margin-bottom: 18px;
    align-items: flex-start;
    display: flex;
}
.media:first-child {
    margin-top: 0;
}
.about-icon .media-body h5 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #fff;
}
.about-icon .media-body p {
    font-size: 15px;
    line-height: 22px;
}
.aboutsec p {
    margin: 20px 0 25px;
}
.about-icon .media img {
    width: 80px;
    margin-right: 15px;
}
.service-sections {
    background-image: url("../img/area-bg.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
}
.areacoverd-p {
    margin: 30px 0 0;
  }
.service-sections .area-title {
    margin-top: 65px;
}
.service-sections .area-title h1 {
    font-size: 30px;
    font-weight: 700;
    color: #ed1c24;
}
.service-sections .area-title h1 span {
    color: #333;
}

/**** About End ****/

/**** popup start ****/

.cancelModel {
    position: absolute;
    top: 40px;
    right: 0;
    bottom: 0;
    left: 25rem;
    z-index: 2000;
    width: 39rem;
    background: #fff;
    height: 13rem;
    border-radius: 5px;
    outline: 0;
}
 #modal {
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
    padding-right: 17px;
}
#fade.in {
    opacity: 1;
}
.cancel-modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5 !important;
}
.cancel-modal-header .close {
    margin-top: -45px;
    margin-right: -20px;
}
.cancel-modal-body {
    position: relative;
    padding: 15px;
}
.cancel-modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5 !important;
}

.cancel-button {
    color: #fff !important;
    background-color: #337ab7 !important;
    border-color: #2e6da4;
}
.close-button {
    color: #333 !important;
    background-color: #fff;
    border-color: #ccc !important;
    margin-left: 8px;
}

/**** popup end ****/

/**** Policy Start ****/

.section-privacypolicy{
    min-height: 100%;
    padding: 35px 0;
}
.policy-section{
	background:#fff;
	padding:20px;
}
.policy-section h2{
	font-weight:600;
}
.policy-section p{
    font-size: 14px;
    line-height: 25px;
    text-align: justify;
    margin-bottom: 10px;
}
.policy-one h3{
    font-size: 18px;
    font-weight: 600;
    margin: 15px 0;
}
.policy-one ul li{
	display:block;
	line-height: 30px;
}
.policy-one ul li i{
	padding-right:10px;
}
.section-privacypolicy ol.breadcrumb{
	background:transparent;
	padding:0px;
}

/**** Policy End ****/

/**** Not Found Page Styles Start ****/
.notfound{
	padding:60px 0 100px 0;
	text-align:center;
}
.notfound img{
	width:300px;
	height:auto;
	margin:auto;
}
.notfound h1{
	font-size:150px;
	font-weight:400;
	color:#333;
	margin:0;
	padding:0;
	line-height:1.2em;
}
.notfound h3{
	font-size:24px;
	font-weight:400;
	color:#333;
	margin:0;
	padding:0 0 50px;
	line-height:1.2em;
}
.notfound p{
	font-size:18px;
	font-weight:300;
	color:#777;
	margin:0;
	padding:0;
	line-height:1.2em;
}
.notfound a{
	font-size:18px;
	font-weight:600;
	color:#fff;
	margin:30px 0 0;
	padding:0;
	line-height:1.2em;
	display:inline-block;
	padding:12px 25px 15px;
	border-radius:6px;
	background:#0d8ada;
	transition:all 300ms linear;
}
.notfound a .fa{
	margin-right:5px;
	font-size:20px;
}
.notfound a:hover{
	color:#383838;
	padding:12px 35px 15px;
}

/**** Not Found Page Styles End ****/

/**** App Download Start ****/

.section-app {
    padding: 50px 0px;
}
.app-download-blk {
    background: #ffffff;
}
.app-content {
    padding: 25px 45px 25px 0;
}
.app-content h2 {
    margin-top: 0px;
}
.app-content h2 {
    font-size: 32px;
    font-weight: 700;
}
.app-content p {
    font-size: 24px;
    margin-top: 20px;
    color: #000;
    line-height: 30px;
}
.app-download-blk .input-group {
    border: 1px solid #CDCDCD;
    border-radius: 10px !important;
    box-shadow: inherit;
    min-height: 84px;
    padding: 10px;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 14px;
    background-color: #fff;
    margin-bottom: 30px;
}
.app-download-blk .input-group .form-control {
    border: none;
    height: 68px;
    line-height: 50px;
    border-radius: 0;
    box-shadow: none;
    font-weight: bold;
}
.app-download-blk .custom-style-btn {
    border-radius: 7px;
}
.app-download-blk .custom-style-btn button {
    background: var(--primary-color);
    border: none;
    border-radius: 7px;
    color: #fff;
    cursor: pointer;
    font-size: 30px;
    height: 68px;
    line-height: 50px;
    width: 100px;
}
.app-content ul {
    padding: 0;
}
.app-content ul li:first-child {
    padding-right: 15px;
}
.app-content ul li {
    display: inline-block;
}

/**** App Download End ****/

/**** Footer Start ****/

.footer {
    background: #fff url(../img/home/footer-bg.png) no-repeat;
    padding-top: 40px;
    color: #6D7A80;
}
.footer-paymethods {
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .footer-paymethods ul {
    display: flex;
    gap: 15px;
    margin-left: 25px;
  }
  .modal-content-search {
    padding: 15px;
  }
  .modal-video {
    border-radius: 15px;
    overflow: hidden;
  }
  .modal-video .closebtn {
    position: absolute;
    right: 10px;
    top: 5px;
    background: rgba(0,0,0,0.2);
    border-radius: 10px;
    padding: 3px;
    font-size: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    box-shadow: 1px 3px 1px rgba(0,0,0,0.4);
  }
.footer h4 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .footer-sectionthree li {
    margin-bottom: 15px;
  }
  .footer-sectionthree a {
    color: #6D7A80;
  }
  .footer-sectionthree {
    padding: 40px 0 25px;
    text-align: center;
  }
  .footer-area-links li {
    display: inline-block;
  }
  .footer-area-links a {
    color: #6D7A80;
  }
  .footer-sectionone {
    text-align: center;
  }
  .footer-sectionone h4 {
    border-top: 1px solid #E9E9E9;
    padding-top: 20px;
  }
  .footer-area-links li:not(:last-child) {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid #e3e3e3;
    margin-bottom: 20px;
  }
  .footer-sectiontwo {
    padding: 25px 0;
  }
  .social-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }
  .app-social ul {
    display: flex;
    gap: 10px;
  }
.home-links ul {
    padding: 20px 0;
    margin: 0;
    border-bottom: 1px solid #fff;
}
.home-links ul li {
    display: inline-block;
    padding-right: 15px;
}
.home-links ul li a {
    font-size: 16px;
    text-decoration: none;
}
.footer-two {
    margin-top: 30px;
}
.footerlist-two h4 {
    font-size: 20px;
    margin-bottom: 30px;
    font-weight: 900;
    text-transform: uppercase;
    position: relative;
}.footerlist-two ul {
    padding: 0;
    margin: 0;
}
.footerlist-two ul li {
    display: block;    
    padding-right: 0;
    line-height: 24px;
    font-size: 16px;
    padding-bottom: 5px;
}
.footer-three {
    margin-top: 30px;
}
.footerlist-two ul li i {
    padding-right: 5px;
}
.footerlist-two ul li a {
    display: inline-block;
}
 .footerlist-two ul li a {
    display: block;
    padding-right: 0;
    line-height: 24px;
    font-size: 16px;
    padding-bottom: 5px;
    text-decoration: none;
}
.app-icons>ul {
    display: flex;
}
.app-icons ul li {
    display: inline-block;
}
.footerlist-two ul li a {
    display: inline-block;
}
.footer-social ul li {
    display: inline-block;
    padding-right: 5px;
}
 .footer-social ul li a {
    padding-right: 0;
    display: flex;
    align-items: center;
    color: #818181;
    font-size: 15px;
}
.footer-social ul li a i {
    margin-right: 5px;
  }
.footerlist-two ul li a {
    display: inline-block;
    text-decoration: none;
}
.foo-icon [class*="fab fa-"] {
    background-color: #6D7A80;
    color: #fff;
    border-radius: 50%;
    display: inline-block;
    height: 32px;
    width: 32px;
    font-size: 18px;
    text-align: center;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer-sectionfour {
  border-top: 1px solid #E9E9E9;
  padding: 20px 0;
}
.footer-last a {
    color: #fff;
    text-decoration: none;
}
.footer-area-links a:hover {
    color: var(--primary-color);
  }
  .footer-sectionthree a:hover {
    color: var(--primary-color);
  }

/**** Footer End ****/
.active-swiper .order_list {
    background: #e5fde5;
  }
  .active-swiper .list-explore p {
    color: var(--primary-color);
  }
/**** Laundryservice Start ****/

.easelaundry-wrapper {
    padding: 60px 0;
}
.easelaundry-wrapper img {
    border-radius: 15px;
}
.aboutsec h1, .easelaundry-wrapper h1 {
    margin: 0px 0px 14px 0px;
}

/**** Laundryservice End ****/

/**** Notification Start ****/

.custom-modal-class .vertical-alignment-helper {
    display: table;
    height: 100%;
    margin: auto;
    width: auto;
}
.custom-modal-class .modal-dialog-centered {
    display: table-cell;
    vertical-align: middle;
}
.custom-modal-class .align-items-center {
    display: flex;
    align-items: center;
}
.custom-modal-class {
    z-index: 2147483640;
}
.custom-modal-class .modal-body {
    background: #fff;
    padding: 40px 40px 50px;
    border-radius: 6px;
    text-align: center;
}
.custom-modal-class .modal-body button.close {
    border: none;
    width: 28px;
    height: 28px;
    padding: 0;
    text-align: center;
    color: #fff;
    top: 10px;
    right: 10px;
    font-size: 26px;
    opacity: 1
}
.custom-modal-class .modal-body button.close:hover {
    color: #a2a2a2;
    opacity: 1;
}
.custom-modal-class .modal-body h3 {
    margin: 0 0 40px;
}
.custom-modal-class .modal-body input {
    width: 100%;
    border: 1px solid #ddd;
    padding: 18px;
    border-radius: 5px;
}
.custom-modal-class .modal-body input[type=submit] {
    width: 100%;
    margin-top: 25px;
    background: #304275;
    color: #fff;
    font-size: 21px;
    border: none;
    transition: all 300ms linear;
}
.custom-modal-class .modal-body input[type=submit]:hover {
    background: #304275;
}
.custom-modal-class.modal {
    position: fixed !important;
    background: rgba(0, 0, 0, 0.59);
}
.custom-modal-class.modal-backdrop {
    background: transparent;
}
.py-50 {
    padding: 50px 0px;
}
.my-32 {
    margin: 32px 0px;
}
.height-50 {
    height: 50px;
}
.custom-style-btn {
    background-color: #00B1F7;
    color: #ffffff;
    border: 0px solid transparent;
    padding: 0px;
}
.custom-style-btn button {
    background: transparent;
    padding: 6px 12px;
}
.custom-style-btn button:hover, .custom-style-btn button:focus {
    color: #ffffff;
    opacity: 0.86;
}

/**** Notification End ****/

/**** MyFlat Start ****/

.offer-wrapper {
    position: relative;
    background-size: cover;
}
.carousel {
    position: relative;
}
.section-header {
    position: relative;
    display: inline-block;
    border-right: 0.6rem solid #ffffff;
    text-align: right;
    padding-top: 3rem;
}
 .section-header::before {
    content: '';
    display: block;
    background-color: #ffffff;
    position: absolute;
}
.section-header::before {
    width: 6px;
    height: 76px;
    top: 0;
    right: 172px;
}
.section-header .section-title {
    position: relative;
    margin-right: 4.6rem;
    top: 48px;
    line-height: 1.1;
    text-transform: uppercase;
    letter-spacing: .01rem;
    color: #ffffff;
}
.section-header .section-title {
    font-weight: 400;
    font-size: 3rem;
    color: #ffffff;
    margin-bottom: 0;
}
.section-header .section-subtitle {
    font-weight: 400;
    font-size: 3rem;
    margin-bottom: 5rem;
}
.section-header .btn {
    min-width: 180px;
    font-weight: 700;
    font-size: 1.2rem;
    color: #ffffff;
    border-radius: 0px;
    border: 0px solid transparent;
}
.section-header::after {
    width: 172px;
    height: 6px;
    top: 0;
    right: 0;
}
.section-header::after {
    content: '';
    display: block;
    background-color: #ffffff;
    position: absolute;
}
 .section-title .myFirst {
    display: inline-block;
    position: absolute;
    left: 9px;
    bottom: 100%;
    margin-bottom: -.2rem;
    font-size: 25px;
    font-weight: 700;
    padding: 7px 14px;
}

/**** MyFlat End ****/

.offer-section {
    background: #323F52 url(../img/offer/offer-vector-bg.png) no-repeat;
    background-position: center;
    background-size: cover;
    padding: 50px 0;
    position: relative;
  }
  .offer-section::after {
    position: absolute;
    content: url('../img/offer/offer-right-vector.png');
    right: 0;
    top: 0;
  }
  .offer-section::before {
    position: absolute;
    content: url('../img/offer/offer-bottom-vector.png');
    left: 0;
    bottom: -6px;
  }
  .offer-vector-wrap::after {
    content: url(../img/offer/offer-wash-img.png);
    position: absolute;
    right: 0;
    bottom: -6px;
  }
  .offer-tag {
    margin-bottom: 25px;
  }
  .offer-wash-bg {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    bottom: -80px;
    background: url(../img/offer/offer-wash-img.png) no-repeat;
    background-position: bottom right;
    right: -12px;
  }
.offer-list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: wrap;
    gap: 40px
  }
  .offer-list li {
    min-width: 30%;
    border: 2px dashed #fff;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    transition: all 0.5s;
    cursor: pointer;
  }
  .offer-btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 15px;
    border: 0;
  }
  .offer-wrap-price {
    display: block;
    font-size: 26px;
    font-weight: 600;
  }
  .offer-wrap-left {
    color: #fff;
    font-size: 17px;
  }
  .offer-wrap-right img {
    margin-top: 0;
    background: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    padding: 3px;
    width: 100%;
    height: 100%;
  }
  .offer-wrap-right {
    display: flex;
    width: 50px;
    height: 50px;
    justify-content: center;
  }
  .offer-hdr {
    font-size: 62px;
    color: #fff;
    font-weight: 600;
    margin: 25px 0 0;
  }
  .offer-tag span {
    color: #fff;
    font-size: 38px;
    background: var(--secondary-color);
    padding: 5px 20px;
    display: inline-flex;
    margin: 0 40px;
    position: relative;
    width: auto;
    height: 70px;
    align-items: center;
  }
  .offer-tag {
    margin: 30px 0;
  }
  .offer-tag span:after {
    position: absolute;
    content: "";
    top: 0;
    right: -33px;
    border-top: 35px solid var(--secondary-color);
    border-bottom: 35px solid var(--secondary-color);
    border-right: 34px solid transparent;
    height: 70px;
  }
  .offer-tag span:before {
    content: "";
    position: absolute;
    border-top: 35px solid var(--secondary-color);
    border-bottom: 35px solid var(--secondary-color);
    border-left: 35px solid transparent;
    height: 70px;
    left: -33px;
    top: 0px;
  }
  .offer-btn .btn {
    background: #fff;
    color: #333;
    border-radius: 25px;
    padding: 7px 30px;
    /*! font-weight: 600; */
    font-size: 22px;
  }
  .offer-desc {
    margin: 30px 0 15px;
    color: #fff;
    font-size: 20px;
  }
  .offer-desc p {
    font-size: 20px;
  }
  .close-btn {
    background: transparent;
    border: 0;
    color: #fff;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 22px;
}

.myorder-backhome i {
    margin-right: 5px;
  }
.res-not-found {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
  }
  .res-not-found:before {
    position: absolute;
    opacity: 0.09;
    z-index: 1;
  }
  .res-not-found-adrs.res-not-found:before {
    content: "";
  }
  .res-not-found-adrs.res-not-found {
    min-height: 300px;
  }
.res-not-icon {
    font-size: 81px;
    color: #a9aaac;
    position: relative;
    z-index: 2;
    background: rgba(255,255,255,0.6);
    padding: 25px;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .res-not-found h2 {
    font-size: 36px;
    font-weight: 600;
    margin-top: 25px;
  }

/**** Flatview Start ****/

.vertical-alignment-helper {
    display: table;
    height: 100%;
    width: 100%;
}
.vertical-align-center {
    display: table-cell;
    vertical-align: middle;
}
.offermodal-body {
    background: #0d8ada url("../img/offer-banner-bg.jpg") no-repeat center center;
    background-size: cover;
    border: none;
    border-radius: 0;
    border-radius: 30px;
    color: #fff;
    padding: 30px;
    text-align: center;
}
 .offerModal .vertical-alignment-helper .modal-content button {
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    opacity: 1;
    top: 0px;
    right: 0px;
    border: none;
}
.vertical-alignment-helper .modal-content button {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 5;
}
.offermodal-body h3 {
    font-size: 36px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    margin: 0;
    padding: 10px 0 15px;
}
.offermodal-body h4 {
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    margin: 0;
    padding: 0;
}
.offermodal-body h4.promo {
    margin-top: 30px;
}
.offermodal-body h4 {
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    margin: 0;
    padding: 0;
}
 .offercode {
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 36px;
    letter-spacing: 5px;
    display: flex;
    margin: 10px 0;
    position: relative;
    height: 70px;
    align-items: center;
    justify-content: center;
}
 .offercode:before {
    left: -16px;
    background: url("../img/right-zigzag.png") no-repeat top center;
}
 .offercode:before {
    position: absolute;
    top: 0;
    width: 16px;
    height: 68px;
    content: '';
}
 .offercode:after {
    right: -16px;
    background: url("../img/left-zigzag.png") no-repeat top center;
}
 .offercode:after {
    position: absolute;
    top: 0;
    width: 16px;
    height: 68px;
    content: '';
}
.offermodal-body p {
    font-size: 16px;
    margin: 0;
}

/**** Flatview End ****/

/**** Orderonline Start ****/
.wash-fold-section {
    background-image: url("../img/wash-fold-bg.jpg");
    padding: 60px 0;
    background-repeat: no-repeat;
    background-size: cover;
}
.wash-fold-card {
    display: flex;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    align-items: center;
}
.wash-fold-text {
    background: #39a4ea;
    border-radius: 20px 10px 10px 20px;
    padding: 40px 25px;
    margin-right: 30px;
    display: flex;
    align-items: center;
}
.wash-fold-text h2 {
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 800;
    line-height: 35px;
}
.wash-fold-info ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.wash-fold-info ul li {
    display: flex;
    align-items: baseline;
}
.wash-fold-info li i {
    font-size: 18px;
    margin-right: 15px;
}
.wash-fold-info ul li p {
    font-size: 20px;
    color: #222;
    font-weight: 600;
    margin-bottom: 17px;
    line-height: 25px;
}

/**** Orderonline End ****/

/**** PricingList Start ****/

.price-section h1 {
    margin-bottom: 25px;
}
.no-float {
    float: none !important;
}
.center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
}
.price-list-items {
    margin: 30px 0;
}
.price-list-items .list-inline {
    list-style: none;
    background: #fff;
    width: auto;
    display: inline-block;
    padding: 10px;
    margin: 0;
    border-radius: 50px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0/7%);
}
.list-inline>li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
}
.price-section .list-inline-item.active a {
    background-color:var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 50px;
}
.price-section .list-inline-item a {
    font-size: 16px;
    color: #323F52;
    padding: 8px 20px;
    border: none;
}
.price-section .btn-group-lg>.btn, .price-section .btn-lg {
    padding: .5rem 1.67rem;
    line-height: 1.5;
    border-radius: 0rem;
}
.tab-content>.active {
    display: block;
}
.tab-pane{
    opacity: 1;
}
.price-list-contents h4 {
    font-weight: 400;
    font-size: 16px;
    padding: 0 0 20px;
    margin: 0 0 30px;
    border-bottom: 1px dashed #e9e9e9;
}
.price-list-contents .homeproduct {
    font-size: 16px;
    margin: 0;
    color: #000;
    text-transform: uppercase;
}
.price-list-contents h4 {
    font-weight: 400;
    font-size: 16px;
    padding: 0 0 20px;
    margin: 0 0 30px;
    border-bottom: 1px dashed #DEDEDE;
}
.price-list-contents .homeprice {
    font-size: 16px;
    margin: 0;
    color: #6A6A6A;
    font-weight: 700;
}

/**** PricingList End ****/

/**** Myprofile Start ****/

.section-myprofile {
    background: #f0f0f0;
    min-height: 100%;
    padding: 20px 0px;
}
.custom-myprofile h1 {
    font-size: 22px;
    margin-bottom: 20px;
    display: inline-block;
}
.profile-details-right {
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
    border-radius: 2px;
    padding: 15px;
}
.profileinfo-header {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 5px;
}
.profile-details-right h5 {
    font-size: 20px;
    display: inline-block;
}
.custom-myprofile span a i {
    padding-right: 5px;
}
.input-mob-no a {
    position: absolute;
    right: 10px;
    z-index: 9;
    top: 8px;
    color: #333;
}
.input-mob-no {
    position: relative;
}
.fa-pencil:before {
    content: "\f040";
}
.user-profile {
    background: #fff;
    box-shadow: 0px 4px 24px rgba(231, 231, 231, 0.3);
    border-radius: 2px;
    padding: 15px;
    margin: 0 0 15px;
}
.user-profile-details h4 {
    margin: 25px 0 0;
    color: #fff;
  }
  .db-img {
    position: absolute;
    top: 15px;
    right: 14px;
  }
  .db-img a {
    background: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .user-profile-img {
    border: 10px solid rgba(196, 196, 196, 0.3);
    border-radius: 50%;
    margin: 25px 0 0;
  }
.slim>input[type=hidden] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    opacity: 0;
}
.slim .slim-file-hopper {
    z-index: 2;
    cursor: auto;
    background: rgba(0, 0, 0, .0001);
}
.slim-file-hopper {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
}
.slim .slim-area .slim-upload-status {
    position: absolute;
    right: 1em;
    top: 1em;
    z-index: 1;
    opacity: 0;
    -webkit-transition: opacity .25s;
    transition: opacity .25s;
    white-space: nowrap;
    line-height: 1.65;
    font-weight: 400;
}
.slim-upload-status {
    padding: 0 .5em;
    border-radius: .3125em;
    font-size: .75em;
    box-shadow: 0 .125em .25em rgba(0, 0, 0, .25);
}
.slim .slim-btn-group {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    overflow: hidden;
}
.slim-btn-group {
    padding: 1.5em 0;
    text-align: center;
}
.slim .slim-btn-group button {
    cursor: pointer;
}
.slim-btn {
    position: relative;
    padding: 0;
    margin: 0 7.2px;
    font-size: 0;
    outline: none;
    width: 36px;
    height: 36px;
    border: none;
    color: #fff;
    background-color: rgba(0, 0, 0, .7);
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: 50%;
    border-radius: 50%;
}
button.backto-home {
    background: var(--secondary-color);
    border: 0px;
    padding: 10px 30px 10px 30px;
    text-align: right;
    border-radius: 2px;
    position: relative;
    font-size: 13px;
    margin-top: 5px;
    color: #fff;
}
button.cancel-btn,a.cancel-btn {
    background: #ececec;
    color: #212121;
    border: 0px;
    padding: 10px 30px 10px 30px;
    text-align: right;
    border-radius: 2px;
    position: relative;
    font-size: 13px;
    margin-top: 5px;
    margin-left: 15px;
}
.user-profile-details {
    text-align: center;
    position: relative;
    padding: 20px;
    background: var(--primary-color);
}
.db-img i {
  color: var(--secondary-color);
}
.myorders {
    border-bottom: 1px solid #f0f0f0;
    padding: 15px;
}
.myorders img {
    display: inline-block;
    padding-right: 10px;
}
.myorders h4 {
    color: #323F52;
font-size: 16px;
margin: 0 0 0 15px;
font-weight: 600;
display: inline-block;
}
.myorders a h4:hover {
    color: var(--primary-color);
  }
.fa.pull-right {
    margin-left: .3em;
}
.fa-angle-right:before {
    content: "\f105";
}
.profile-info {
    width: calc(100% - 0px);
    padding: 10px 0px 10px 53px;
}

.myorders i {
    border: 1px solid var(--primary-color);
    border-radius: 50%;
    color: var(--primary-color);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .myorders a {
    display: flex;
    width: 100%;
    align-items: center;
  }
.myorders {
    border-bottom: 1px solid #F4F4F4;
padding: 15px;
display: flex;
align-items: center;
}
.btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
}
.profileinfo-header {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 5px;
}
.profile-details-right h5 {
    font-size: 20px;
    display: inline-block;
}
.personal-info {
    margin: 20px 0px;
}
.personal-info label {
    font-weight: 600;
    font-size: 16px;
    color: var(--secondary-color);
}
.personal-info p {
    font-size: 13px;
    color: #1B1B1B;
}
.signin-header h1 {
    font-size: 22px;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 0px;
}
.forgot-pwd {
    margin-bottom: 10px;
}
.register-link span a i {
    padding-right: 5px;
}
.register-link a, .login-link a {
    color: #ED1C24;
}
.register-link, .login-link {
    padding-bottom: 15px;
}
.pull-right a {
    text-decoration: none;
    color: #23527c;
}
.manageaddress {
    border-bottom: 1px solid #ececec;
    padding-bottom: 5px;
}
.manageaddress a {
    color: #0d8ada;
    text-decoration: none;
}
.filled-address-custom {
    border: 1px solid #ececec;
    padding: 15px;
    border-radius: 3px;
    margin: 25px 15px 0px 15px;
    border-radius: 3px;
    width: 29%;
    margin-top: 15px !important;
}
.dry-group {
    background: #fff;
    box-shadow: 0px 6px 14px rgba(174, 174, 174, 0.25);
    border-radius: 4px;
    margin: 20px 0;
    padding: 20px;
    width: 305px;
    display: flex;
    flex-direction: column;
  }
  .dry-group-list {
    flex-flow: wrap;
    gap: 20px;
    justify-content: center;
  }
  .dry-cleaner-head {
    border-bottom: 1px solid #d8dadb;
    padding-bottom: 15px;
  }
  .visit-web {
    margin: 20px 0 0;
  }
  .address-dry {
    color: #323F52;
    margin-top: 15px;
  }
  .dry-cleaner-head h4 {
    color: var(--secondary-color);
    font-size: 20px;
  }
  .visit-web .btn {
    background: #323F52;
    color: #fff;
  }
.custom-address-title h4 {
    display: inline-block;
    font-size: 12px;
    background: var(--secondary-color);
    padding: 5px;
    border-radius: 4px;
    font-weight: 600;
    color: #fff;
}
.custom-edit-btn {
    display: inline-block;
}
.custom-edit-btn i {
  color: var(--secondary-color);
}
.custom-edit-btn a, .custom-edit-btn a:not([href]:not([class])) {
    font-size: 12px;
}
.custom-edit-btn span a i.address-edit {
    padding-right: 10px;
}
.fa-pencil:before {
    content: "\f040";
}
.svd-address {
    font-size: 13px;
    color: #999;
}
#deleteprofile {
    position: absolute;
    top: 35rem;
    background: #fff;
    right: 33rem;
    width: 25rem;
}
.change-avatar .change-photo-btn {
    margin: 0 0 10px;
    width: 150px;
}
.change-photo-btn {
    background-color: #20c0f3;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 13px;
    font-weight: 600;
    margin: 0 auto;
    padding: 10px 15px;
    position: relative;
    transition: .3s;
    text-align: center;
    width: 160px;
}
.change-photo-btn input.upload {
    bottom: 0;
    cursor: pointer;
    filter: alpha(opacity=0);
    left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 160px;
}
.ml-20{
    margin-left: 20px;
}

/**** Myprofile End ****/

/**** Profile Start ****/

ul.header-link li ul li {
    padding: 0;
    width: 100%;
    border-bottom: 1px solid #ececec;
}
.menu-hover-dropdown .dropdown-menu li a {
    font-size: 13px;
}
.header-nav .dropdown-menu li a {
    padding: 10px 15px;
    font-weight: 400;
}
.header-link .dropdown-menu ul {
    padding: 0;
}
.presentation .menuitem{
    display: flex !important;
    color: #333;
    text-decoration: none;
    align-items: center;
}
.profile .presentation{
    border-bottom: 1px solid #ececec;
    list-style-type: none;
    padding: 10px;
}
.section-myorders {
    background: #f0f0f0;
    min-height: 100%;
    padding: 15px 0;
}
.myorder-row{
    margin-bottom: 15px;
}
.section-myorders h1 {
    font-size: 22px;
    margin-bottom: 0;
    margin-top: 5px;
}
.myorder-backhome span {
    margin: 7px 0 0 15px;
    display: inline-block;
}
.my-orders {
    background: #fff;
    box-shadow: 0px 4px 24px rgba(228, 228, 228, 0.25);
    border-radius: 6px;
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #E6E6E6;
}
.myorder-header {
    background: rgba(231, 231, 231, 0.3);
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.order-idtag {
    background: var(--primary-color);
    color: #fff;
    padding: 10px;
    border-radius: 3px;
    margin-top: 0;
    display: inline-block;
    font-weight: 600;
    font-size: 16px;
}
.order-id span {
    background: #323F52;
    color: #fff;
    padding: 10px;
    border-radius: 3px;
    margin-top: 0;
    display: inline-block;
    font-weight: 600;
    font-size: 16px;
}
.order-status ul {
    margin: 0;
    padding: 0;
}
.order-status li.order-pending {
    color: #ff7a7a;
}
.order-status li {
    display: inline-block;
    border: 1px solid #ececec;
    padding: 10px 25px;
    margin-right: 10px;
    border-radius: 50px;
}
.order-status li img {
    display: inline-block;
    padding-right: 10px;
}
.order-status li a.order-track, .order-status li a.order-track:hover {
    color: #681986;
}
.order-status li:last-child {
    margin-right: 0;
}
.order-status li.order-approved {
    color: #35cc9a;
}
.order-status li:last-child {
    margin-right: 0;
}
.order-status li.order-approved a {
    color: #ed1c24;
}
.orders {
    padding: 10px 0;
}
.orderimg img {
    text-align: center;
    margin: 0 auto;
}
.customorder {
    display: flex;
    justify-content: space-between;
  }
  .custom-order {
    padding: 0 20px;
  }
  .rht-details {
    font-size: 20px;
    color: #5F5F5F;
    font-weight: 400;
  }
  .order-status {
    border-top: 1px solid #E6E6E6;
    padding: 15px 0 0;
    text-align: right;
  }
span.lft-order {
    font-size: 20px;
    font-weight: 600;
    display: block;
}
.custom-order-price {
    font-size: 24px;
    font-weight: 600;
}
.section-myorders {
    background: #f0f0f0;
    min-height: 100%;
}
.orderview-id h1 {
    margin: 20px 0px;
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
}
.orderview-id span {
    padding-top: 15px;
}
.orderview-id span a i {
    padding-right: 5px;
}
.menswear-checkout {
    box-shadow: 0px 4px 24px rgba(228, 228, 228, 0.25);
    background: #fff;
    margin-bottom: 20px;
    border: 1px solid #E6E6E6;
    border-radius: 6px;
}
.menswear_header {
    padding: 10px;
}
.menswear_header span {
    display: inline-block;
}
.menswear_header span img {
    width: 40px;
    border-radius: 50%;
}
.menswear_header span h3 {
    font-size: 15px;
    font-weight: 600;
    margin: 0 0 0 10px;
    padding: 0px;
}
.cloth-expand {
    padding: 10px;
    border-top: 1px solid #DEDEDE;
}
.listcloth-lft {
    display: inline-block;
}
.listcloth-lft h2 {
    font-size: 14px;
    display: inline-block;
}
.listcloth-lft img {
    width: 30px;
    display: inline-block;
    margin-right: 10px;
}
.listcloth-lft span {
    display: inline-block;
    padding-left: 10px;
    color: #999;
}
span.orderedd-item-qty {
    padding-right: 40px;
    font-size: 15px;
}
span.orderedd-item-price {
    font-size: 17px;
    font-weight: 600;
    color: #323F52;
    display: inline-block;
    position: relative;
    width: 100%;
    max-width: 30%;
    min-width: 150px;
    text-align: right;
}
.ordertotal-amt {
    font-size: 18px;
    font-weight: 600;
}
.ordertotal {
    box-shadow: 1px 7px 11px -7px #aaabab;
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    float: left;
    width: 100%;
}
.ordertotal h2 {
    font-size: 18px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 15px;
}
.ordertotal img {
    display: inline-block;
    padding-right: 10px;
}
.orders-total {
    margin-bottom: 15px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    flex-flow: wrap;
}
.menswear-checkout-box {
    background: #fff;
  }
  .ordertotal-count {
    padding: 20px;
  }
.orders-total p {
    margin: 0px;
    line-height: 1.8
}
ul.order-status {
    padding: 0px;
}
.order-status li.order-pending {
    color: #ff7a7a;
}
.order-status li {
    display: inline-block;
    border: 1px solid #E6E6E6;
    padding: 10px 25px 10px 25px;
    margin-right: 10px;
    border-radius: 50px;
    background: #fff;
}
.order-status li:last-child {
    margin-right: 0px;
}
.myorder-pagination {
    text-align: center;
}
.pagination li a {
    border: none !important;
    background: transparent;
    cursor: pointer;
    border-radius: 50%;
}
.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
    z-index: 3;
    color: var(--primary-color);
    cursor: default;
}
.pagination li {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: 1px solid #e5e5e5;
    padding: 5px 10px;
    height: 40px;
  }
  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
/**** Profile End ****/

/**** Review Start ****/

.revi-section {
    background-color: #fff;
    padding: 50px 0 80px;
}
.revs-tab .nav-tabs>li.active>a, .revs-tab .nav-tabs>li.active>a:focus, .revs-tab .nav-tabs>li.active>a:hover {
    color: #323F52 !important;
    cursor: default;
    background-color: #fff;
    border: none;
    border-bottom: 5px solid #323F52;
}
.revs-tab .nav-tabs>li>a {
    font-size: 16px;
    font-weight: 600;
    border: none;
    margin-right: 30px;
    text-decoration: none;
    padding: 10px 15px;
    display: block;
}
.rev-section li {
    background-color: #fff;
    padding: 20px;
    margin-top: 15px;
    display: flex;
    box-shadow: 0px 4px 34px rgb(215 215 215 / 25%);
}
.rev-section li img {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    margin-right: 20px;
}
.rev-section .rev-info h2 {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.rev-section .rev-info p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}
.rev-rating {
    display: flex;
    margin-top: 15px;
    align-items: center;
}
.rev-rating span {
    background-color: #FEBE42;
    padding: 3px 7px;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}
.rev-rating i.selected {
    color: #FEBE42;
}
.rev-rating i {
    font-size: 18px;
    color: #cecece;
    margin: 0px 0 0 5px;
}
.rev-info ul li img {
    width: 35px;
    height: 35px;
}
.rev-info ul li h4 {
    font-size: 16px !important;
    font-weight: 400 !important;
}
.rev-info ul li .rev-rating span {
    font-size: 12px;
    font-weight: 600;
}
.rev-info ul li .rev-rating i {
    font-size: 16px;
}
.rev-info ul li {
    box-shadow: none;
    padding: 0 20px 0 0 !important;
    align-items: center;
}

/**** Review End ****/

/**** Rushmoor Start ****/

.area-covered {
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.77), rgba(0, 0, 0, 0.50)), url("../img/area-bg.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
}
.area-title {
    margin-top: 65px;
}
.area-title h1 {
    font-size: 30px;
    font-weight: 700;
    color: #FFF200;
}
.area-title h1 span {
    color: #fff;
}
.area-search-form, .another-search-area, .area-list-01 {
    width: 60%;
    margin: 20px auto;
}
#dropdownrow{
    position: absolute;
    background: #fff;
    top: 60px;
    width: 100%;
    left: 1px;
    z-index: 2;
}
.submit-area-btn {
    border-radius: 2px;
    padding: 15px 32px 15px;
    cursor: pointer;
    background: #323F52;
    color: #ffffff;
    font-weight: 700;
    border: 0;
    height: 60px;
}
.area-list-01 h3 {
    color: #fff;
    border-top: 1px solid rgba(230, 230, 230, 0.25);
    margin: 35px 0 35px;
    padding: 35px 0 0;
    font-weight: 700;
    font-size: 24px;
}
.arcode-slider a {
    border: 1px dashed #fff;
    padding: 10px 15px;
    border-radius: 50px;
    font-weight: 600;
    color: #fff;
    background: #515151;
    margin: 0 10px;
    display: inline-block;
    min-width: 100px;
    margin-bottom: 10px;
}
.arcode-slider .swiper-button-next::after,.arcode-slider .swiper-button-prev::after {
    color: #fff;
  }
  .arcode-slider .swiper-button-prev,.arcode-slider .swiper-button-next {
    top: 23px;
  }

.areacoverd-proc-steps h2 {
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 25px;
  }
  .areacoverd-proc-steps ul {
    display: flex;
    padding: 0;
    gap: 10px;
  }
  .areacoverd-proc-steps li {
    list-style: none;
    display: flex;
    align-items: center;
    flex: 1 0 0;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    padding: 7px;
  }
  .areacoverd-qorder {    
    background: url("../img/area-covered.jpg");
    padding: 100px 50px 50px;
    background-size: 100% 100%;
    background-position: center;
    margin: 50px 0 75px;
  }
  .prco-img {
    background: #323F52;
    border-radius: 5px;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .prco-img img {
    max-height: 36px;
  }
  .prco-desc h4 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
  }
  .prco-desc {
    margin: 0 0 0 15px;
    color: #4F5862;
  }
  
.areacoverd-qorder p {
    color: #fff;
    font-size: 24px;
    max-width: 550px;
    margin: 0 0 25px;
  }
  .areacoverd-qorder a {
    background: #333333;
    color: #fff;
    padding: 15px;
    display: inline-block;
    border-radius: 10px;
    min-width: 250px;
    text-align: center;
    font-weight: 700;
  }
.service-content-wrapper {
    margin-bottom: 70px;
}
.list-group-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.no-border-li li {
    border: 0 solid transparent!important;
}
.list-icon-style li i {
    font-size: 18px;
    color: #ed1c24;
}
.areacoverd-footer h3 {
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 25px;
  }
  .areacoverd-footer h3 span{
    color: #323F52;
  }
  .areacoverd-proc-steps {
    margin: 50px 0 0;
  }
/**** Rushmoor End ****/

/**** Coverd Start ****/

.abt-section {
    padding: 50px 0;
    background: #fff;
}
.section-title h1 {
    font-weight: 600;
    margin-bottom: 35px;
    font-size: 36px;
    color: #000;
}
.alphabet-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    padding-bottom: 30px;
}
.alphabet-header a{
    font-size: 18px;
    text-decoration: none;
    transition: 0.5s all;
    padding: 10px 20px;
    margin: 10px;
    border: 1px solid #EBEBEB;
    -webkit-transition: 0.5s all;
    box-shadow: 0px 4px 24px rgba(247, 247, 247, 0.25);
    font-weight: 600;
    color: var(--secondary-color);
}
.city-list h3{
    color: var(--primary-color);
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 36px;
}
.city-list ul li a:hover {
    color: #323F52;

}
.city-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.city-list ul li a {
    font-size: 16px;
    color: #777;
    text-decoration: none;
}
.city-list:after {
    position: absolute;
    content: "";
    background: #fff;
    width: 1px;
    height: 20px;
    right: -1px;
    bottom: 0;
}
.city-list {
    padding: 20px;
    flex: 0 0 25%;
    border-right: 1px solid #DEDEDE;
    border-bottom: 1px solid #DEDEDE;
    margin-bottom: 20px;
    position: relative;
    scroll-margin-top: 150px;
}
.alphabet-cont .city-list:nth-child(4n) {
    border-right: 0;
}
.alphabet-cont {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-flow: wrap;
}

/**** Coverd End ****/

/**** FAQ Start ****/

.section-faq {
    background: #f0f0f0;
    min-height: 100%;
    padding: 35px 0;
}
.section-faq ol.breadcrumb {
    background: transparent;
    padding: 0px;
}
.faq-content p {
    font-size: 14px;
    line-height: 1.8;
}
.panel-group {
    margin-bottom: 20px;
}
.faq-grp .accordion-item {
    border: 0px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}
.faq-grp .accordion-button:not(.collapsed) {
    color: var(--primary-color);
    background-color: #fff;
    box-shadow: 0 0 0;
}
.faq-grp .accordion-button {
    font-weight: 600;
    font-size: 17px;
    color: #333;
}
.faq-grp .panel-heading {
    background: #fff;
    border: 0px;
}
.panel-heading {
    padding: 20px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.panel-group .panel {
    border-radius: 4px;
}
.faq-grp .panel-heading h4 {
    margin: 0px;
    font-weight: 600;
    font-size: 17px;
}
.faq-grp .panel-heading h4 a {
    color: #333;
    text-decoration: none;
}
.panel-default>.panel-heading+.panel-collapse>.panel-body {
    border: 0px;
}
.panel-heading .accordion-toggle.collapsed:after {
    content: "\e080";
}
.panel-heading .accordion-toggle:after {
    content: "\e114";
    float: right;
    color: grey;
}

/**** FAQ End ****/

/**** Pricing Start ****/

.breadcrumpset {
    background: url("../img/breadcrump.png");
    text-align: center;
    padding: 35px 0 35px;
    background-repeat: no-repeat;
    background-size: cover;
}
.breadcrumpview ul {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 15px 0 0;
    list-style: none;
}
.breadcrumpview ul li {
    margin: 0 15px;
    color: #fff;
    position: relative;
}
.breadcrumpview ul li:first-child::after {
    position: absolute;
    content: "/";
    right: -21px;
    font-size: 20px;
    top: -3px;
}
.breadcrumpview i {
    color: #FFF200;
}
.breadcrumpview h2 {
    color: #fff;
}
 .pricing-list-cloth {
    box-shadow: 0px 4px 24px rgba(183, 183, 183, 0.25);
    border-radius: 7px;
    padding: 50px 60px;
    margin: 30px 0 0;
    background: #fff;
}
.price-section .list-group-item {
    border: 0px solid transparent;
    border-bottom: 1px solid #ddd;
    margin-bottom: 0;
    padding: 23px 0px;
    background-color: transparent;
}
.list-group-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.price-list {
    display: flex;
    align-items: center;
    font-size: 16px;
}
.price-list .price-pro-title {
    -ms-flex: 60%;
    flex: 0 0 60%;
    max-width: 60%;
    font-weight: bold;
    font-size: 16px;
    color: var(--primary-color);
}
.price-list .price-pro-cont {
    -ms-flex: 40%;
    flex: 0 0 40%;
    max-width: 40%;
    margin-left: auto;
    padding-left: 10px;
    text-align: right;
}
.swiper-wrapper a{
    text-decoration: none;
}

/**** Pricing End ****/

/**** Sidebar Start ****/
#sidebar {
    width:250px;
    position:fixed;
    top:0;
    left:-260px;
    height:100vh;
    z-index:999;
    background:#fff;
    color:#fff;
    transition:all .3s;
    overflow-y:scroll;
    box-shadow:3px 3px 3px rgba(0,0,0,.2);
    padding: 0;
}
#sidebar.active {
    left:0
}
#dismiss {
    width:35px;
    height:35px;
    line-height:35px;
    text-align:center;
    background: var(--primary-color);
    position:absolute;
    top:16px;
    right:20px;
    cursor:pointer;
    -webkit-transition:all .3s;
    -o-transition:all .3s;
    transition:all .3s;
    border-radius:50%
}
#dismiss:hover {
    background:#fff;
    color:#7386d5
}
#overlay {
    display:none;
    position:fixed;
    width:100vw;
    height:100vh;
    background:rgba(0,0,0,.7);
    z-index:998;
    opacity:0;
    transition:all .5s ease-in-out
}
#overlay.active {
    display:block;
    opacity:1;
    top: 0;
}
#sidebar .sidebar-header {
    padding:20px;
    background:#5b5b5b;
    font-weight:600
}
#sidebar ul p {
    color:#fff;
    padding:10px
}
#sidebar ul li {
    border-bottom:1px solid #ececec
}
#sidebar ul li:last-child {
    border:0
}
#sidebar ul li a {
    padding:15px;
    font-size:1em;
    display:block;
    color:#383838
}
#sidebar ul li a:hover {
    color:#7386d5;
    background:#fff
}
#sidebar ul li.active>a {
    color:#fff;
    background:#6d7fcc;
}
.logo span {
    display:none
}
.logo a {
    display:inline-block
}

/**** Sidebar End ****/

  .swal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0;
    overflow-y: auto;
    background-color: rgba(0,0,0,.4);
    z-index: 10000;
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s;
  }
  .swal-overlay--show-modal {
    opacity: 1;
    pointer-events: auto;
  }
@media only screen and (max-width: 767px) {
  .logo {
    width: 100%;
}
   
    .pricing-list-cloth {
        padding: 10px;
      }
    .price-section {
        padding: 0;
      }
    .banner-search-wrap {
        padding: 40px 0;
      }
    .offer-hdr {
        font-size: 32px;
    }
    .offer-tag span {
        font-size: 28px;
    }
    .offer-desc p {
        font-size: 16px;
      }
      .offer-vector-wrap::after {
        display: none;
      }
      .offer-grid {
        text-align: center;
      }
.carousel-inner {
    flex-direction: column;
}
.logins-blk a {
    padding: 14px 8px !important;
    font-size: 14px;
}
.offer-list li {
    width: 100%;
}
.offer-section {
    padding: 25px;
}
.link-whatsapp a {
    width: 32px;
    height: 32px;
    font-size: 18px;
  }
  .link-whatsapp {
    left: 15px;
    right: auto;
  }
}

@media (min-width: 1200px){
.maincontainer {
    width: 1170px;
}
.section-header {
    float: left;
}
/* .work-item .work-inner .work-img::after {
    display: inherit;
    height: auto;
    width: 33%;
    left: 100%;
    top: 50%;
    border-bottom: 2px dashed #aaa;
  } */
}

@media only screen and (min-width: 1600px){

    .pr-50 { padding-right:50px; }
  
      .aboutsec .about-content-right, .easelaundry-wrapper .easelaundry-content-left   {
          padding:0px 32px;
      }
  }
  
  @media only screen and (max-width: 1199px){
    #site_logo {
      max-width: 250px;
    }
  
    /* home page start */
    .banner-search-wrap {
        padding: 25px 0;
      }
  .app-content h2{margin:0px;}
  .app-content ul{padding:0px;}
  .app-img {max-width: 45%;}
  .app-content{max-width:55%;padding: 0px 45px 25px 45px;}
  .dryclean-form h2{ margin-bottom:25px;}
  .form-laundry img {padding-right: 7px;padding-left: 9px;}
  .form-laundry p{font-size:13px;}
  .form-laundry i, .dry-cleaning i{top: 22px;}
  .aboutsec { padding:50px 50px 0px 50px; }
  .easelaundry-wrapper { padding:50px; }
  .aboutsec .about-img-left, .easelaundry-wrapper .easelaundry-img-right { margin-bottom: 32px; }
  .aboutsec .box-grid-style {min-height: auto;  max-height: auto;}
  .vertical-align {display: block !important;}
  .home-banner-header h2, .home-banner-header p{font-size:30px;}
  .home-banner-right .modal-dialog{ width:100%;}
      /* home page end */
  .home_single_search{background-size:cover;}
  .home_single_search h1{ font-size:30px;}
  .home_single_search p{ font-size:16px; line-height:1.6em; margin-bottom:30px; padding-right:30px;}
  
  
    /*home page new offer starts*/
  
    .section-header .section-title {
      font-size: 6.8rem;
    }
  
    .section-header .section-subtitle {
      font-size: 3.2rem;
    }
  
      /* service page start */
      .ct-service-slider{width:350px !important;}
      /* service page end */
  
      .price-tab-left .nav-tabs .nav-link span {
          width: 86px;
      }
      .mens-wear i, .womens-wear i, .kids-wear i, .menswear i, .womenswear i {
        font-size: 13px;
        font-weight: 400;
        bottom: 9px;
        right: 10px;
    }
    .list-swiper {
        width: 95% !important;
    }

    .swiper-btn .swiper-button-prev {
        left: -10px;
    }

    .swiper-btn .swiper-button-next {
        right: -10px;
    }

    .list-explore small {
        font-size: 11px;
    }

    .user-img img {
        left: 5px;
    }

    .menswears a[aria-expanded="true"] .orderlist-men {
        background: linear-gradient(130deg, #ED1C24 33%, #fff 0%);
    }

    .menswears a[aria-expanded="true"] .orderlist-women {
        background: linear-gradient(130deg, #3AA4EA 33%, #fff 0%);
    }

    .menswears a[aria-expanded="true"] .orderlist-kids {
        background: linear-gradient(131deg, #33c9dc 33%, #fff 0%);
    }

    .menswears a[aria-expanded="true"] .orderlist-four {
        background: linear-gradient(131deg, #cddc39 33%, #fff 0%);
    }

    .menswears a[aria-expanded="true"] .orderlist-fifth {
        background: linear-gradient(131deg, #186da3 33%, #fff 0%);
    }

    .menswears a[aria-expanded="true"] .orderlist-sixth {
        background: linear-gradient(131deg, #795548 33%, #fff 0%);
    }

    .pickupaddress-title ul li a {
        font-size: 12px;
    }

    .app-icons ul {
        padding-left: 0px;
    }

    .swiper-btn-pickup .swiper-button-next {
        right: 4px;
    }

    .swiper-btn-pickup .swiper-button-prev {
        left: 4px;
    }

    .footer .row .col-md-6:first-child, .footer .row .col-md-6:nth-child(2) {
        width: 50%;
        float: left;
    }

    .header-link {
        padding: 0px;
    }

    input#cardnumber {
        width: 100%;
    }

    .card-expiry {
        width: 60%;
    }

    .saved-cards .card-cvv {
        width: 100%;
    }

    .card-cvv .formgrp-custom {
        width: 100%;
        text-align: left;
    }

    .summary-payment.affix, .summary-payment.affix-bottom {
        width: 290px;
    }
  }
  @media only screen and (max-width: 1349.98px) {
  .work-item .work-inner .work-img span {
    width: 30px;
    height: 30px;
    right: -15px;
    font-size: 12px;
  }
}
  @media only screen and (min-width:1400px){
  
  /*.price-tab-right .slick-list .slick-track { width:1400px !important;}*/
  
  /*.price-tab-right .slick-initialized .slick-slide {
      width: 347px !important;
  }*/
  
  }
  @media (min-width: 992px) {
    .work-item {
        width:30%;
        margin-bottom:0
       }
  }
  
  @media only screen and (max-width:991px){
    #site_logo {
      max-width: 200px;
    }
    ul.header-link li.nfm {
      display: none;
    }
    .header-nav-box,.header-box {
      padding: 5px;
    }

    .foo-icon [class*="fab fa-"] { 
        width: 24px;
        height: 24px;
        font-size: 16px;
    }
    .link-whatsapp a {
        width: 30px;
        height: 30px;
        font-size: 16px;
    }
    .footerlist-two ul li i {
        padding: 0;
    }
    .footerlist-two h4 {
        font-size: 13px;
        font-weight: 600;
    }
    .header-link li a {
        font-size: 13px;
    }
    .header-link li .cart-btn {
        width: 42px;
        height: 42px;
    }
    ul.header-link li.login a {
        padding: 5px 5px;
    }
    ul.header-link li {
        padding: 10px 0px 10px 5px;
    }
    .how-work-wrapper {
        margin: 0 -15px;
      }
      .work-item {
        width: 50%;
        padding: 0 15px;
        margin-bottom: 30px;
      }
      /* home page */
      ul.header-link{padding:0px;}
      .form-search {width: 100%;}
      .howwork-content h2{line-height: 30px;height: 60px;}
      .home-banner{background-position: 30% 0;}
      .home-links ul li a {font-size: 12px;}
      .offer-img > .col-md-6:first-child a img{width:49%;float:left;margin-right:15px;}
      .offer-img > .col-md-6:nth-child(2) a img{width:48%;float:left;}
      .offer-code {float: left;margin-bottom:15px;}
      .footerlist-two ul li, .footerlist-two ul li a,p.footer-last {font-size: 10px;}
      .app-icons ul{padding:0px;}
      .footer .row .col-md-6:first-child,.footer .row .col-md-6:nth-child(2){width:50%;float:left;}
      ul.footer-conditions {padding: 0px;}
      .coupon-code label{font-size:12px;}
      .coupon-code .offer-group {width: 40%;display: inline-flex;}
      .home-banner-header h2{font-size:20px; line-height:24px;}
      .home-banner-header p{font-size:20px;}
      .home-banner-content{margin: 10px 0px;}
      .home-bnr-contentleft a.learn-more{margin: 20px 0px 25px 0px;}
      .dryclean-form{padding:0px 25px 10px 25px;}
      .form-search{padding: 15px 0px 2px 0px;}
      .app-content ul li a img{width:140px;}
      .home-bnr-contentleft span{font-size:14px; line-height:24px; margin-bottom:25px;}
      .home-banner-right{padding:30px 30px 10px 30px;}
  
      .price-tab-left .nav-tabs .nav-link span {
          width: 95px;
      }
  
  
    .app-img, .app-content {max-width: 100%;}
  
  
  
    .section-header .section-title {
      font-size: 4.1rem;
    }
  
    .section-header .section-subtitle {
      font-size: 2.3rem;
    }
    .about-icon .media {
      width: 100%;
    }
  
      /* home page */
      
  .home_single_search{text-align:center;}
  .home_single_search h1{ padding:0 70px 20px;}
  .home_single_search p{ padding:0 40px;}
  .postcodeform{ float:none; overflow:hidden;}
  .video_wrapper img{ width:300px; height:auto;}
  #video{ float:none;}
  /* pickup page */
    /*.formgrp-custom {width: 50%;display: inline-block;float:left;}*/
    .formgrp-custom p {
        display: none;
    }

    .address_optional {
        border: 0px;
        position: static;
        height: auto;
        margin-bottom: 22px;
    }

    .address_optional p {
        position: static;
        text-align: center;
        margin: 0 auto;
    }

    .pickupaddress-title ul {
        padding-right: 0px;
        padding-left: 0px;
        margin-top: 0px;
    }

    /*.pickupaddress-title span {width: auto;text-align: right;float: right;display: inline-block;}*/
    .pickupaddress-title ul li:first-child {
        padding-right: 10px;
    }

    .pickup-address h4 {
        font-size: 14px;
    }

    .pickupaddress-title ul li a {
        font-weight: 600;
        font-size: 13px;
    }

    .pickupaddress-title span.same-pickup {
        width: auto;
        text-align: right;
        float: right;
        display: inline-block;
    }

    .swiper-btn-pickup .swiper-button-next, .swiper-btn-pickup .swiper-button-prev {
        transform: translateY(0%);
        position: absolute;
        bottom: 0px;
    }

    select:valid ~ .input-validation::before {
        top: 0px;
    }

    .summary-payment.affix {
        position: static;
        width: 100%;
    }

    .card-detail {
        float: left;
    }

    .card-expiry {
        width: 50%;
        padding: 5px 0px 0px 20px;
        text-align: left;
        float: left;
    }

    .card-cvv .formgrp-custom {
        width: 100%;
        text-align: left;
    }

    ul.default_hide1 {
        visibility: visible;
        bottom: 0px;
    }

    .coupon-code .offer-group {
        width: 35%;
    }

    .saved-cards .savedcard-verify p {
        display: inline;
        margin-bottom: 10px;
    }

    .savedcard-verify {
        margin-bottom: 15px;
    }

    .saved-cards .card-cvv {
        margin-top: 5px;
    }

    .saved-cards .card-cvv .formgrp-custom {
        margin-bottom: 0px;
    }

    .saved-cards .card-cvv {
        width: 100%;
    }

    .amt-payable span.amt-discount, .amt-payable span.amt-payable-without-deductions {
        font-size: 13px;
    }

    span.amtpayable-title {
        font-size: 14px;
    }

    .amt-payable {
        padding: 15px;
        border-radius: 0px;
        margin-bottom: 0px;
        float: none;
    }

    .summary-payment {
        width: 100%;
    }
   
    /* pickup page */
  
  }
  @media only screen and (max-width:767px) {
    
.intro-section {
  padding: 30px 0;
}
.section-title h2 {
  font-size: 24px;
}
.section-title p {
  font-size: 16px;
}
.section-card {
  padding: 10px;
}
.intro-content {
  padding: 0;
}
.intro-data-box {
  padding: 10px;
}
.intro-data-box li::after {
  display: none;
}
.highlight-box {
  padding: 10px;
}
.highlight-overlay {
  position: unset;
  width: 100%;
  margin: 0;
  padding: 15px 0 0;
  background: transparent;
}
.highlight-content li {
  width: 100%;
}
.section-title2 h2 {
  font-size: 24px;
}
.services-slider-grid.services-slider-grid-lbl {
  display: none;
}
.services-slider-grid {
  margin-bottom: 20px;
}
.mobapp-content {
  padding: 15px;
}
.footer-social ul li {
  width: 100%;
  margin: 0 0 15px;
}
    /*newtemplate start*/
    .home-img-grid ul {
      gap: 5px;
    }
.homesearch {
    padding: 10px 0 0;
  }
  .homesearch p {
    padding: 0;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .postcodeform input[type="submit"], .postcodeform .btn {
    padding: 10px;
  }
  .postcodeform input[type="submit"], .postcodeform .btn img {
    display: none;
  }
  .trust-section {
    padding: 20px 0;
  }
  .section-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .trust-section .section-title {
    margin-bottom: 20px;
  }
  .trust-content p {
    margin-bottom: 20px;
  }
  .trust-section h4 {
    margin: 10px 0;
  }
  .priority-img {
    margin-bottom: 20px;
  }
  .priority-card {
    margin-bottom: 20px;
  }
  .mobapp-subtract-body {
    padding: 20px 0;
  }
  .mobapp-img {
    padding: 25px 0 0;
  }
  .subscriptioninfo-section {
    padding: 20px 0;
  }
  .subscriptioninfo-content {
  }
  .subscriptioninfo-box {
    margin: 20px 0;
  }
  .services-section {
    padding: 30px 0;
  }
  .testimonial-section {
    padding: 30px 0;
  }
  .testimonial-subtract-top {
    display: none;
  }
  .testimonial-section .text-end {
    text-align: center !important;
    margin-bottom: 10px;
  }
  .testimonial-img {
    margin-bottom: 20px;
  }
  .testimonial-overlay {
    padding: 15px;
  }
  .testimonial-card {
    margin-top: 20px;
  }
  .faq-section {
    padding: 20px 0;
  }
  .faq-subtract-top {
    display: none;
  }
  .faq-content {
    margin: 0;
  }
  .mobapp-img-overlay {
    padding: 10px;
    margin-left: 10px;
  }
  .mobapp-img-overlay p {
    font-size: 16px;
  }
  .mobapp-img-overlay h4 {
    font-size: 18px;
  }
  .latestnews-section {
    padding: 20px 0;
  }
  .latestnews-section .text-end {
    text-align: center !important;
    margin-bottom: 20px;
  }
  .latestnews-card {
    margin-bottom: 20px;
  }
  .social-links {
    flex-direction: column;
    gap: 20px;
  }
  .footer-paymethods {
    flex-direction: column;
    gap: 10px;
    justify-content: center;
  }
  .footer-paymethods ul {
    margin: 0;
  }
    /*newtemplate end*/
    .work-item .work-inner .work-content p {
        font-size: 15px !important;
      }
      /* home page */
       .work-item {
        width: 100%;
      }
.login-grid {
    width: 100%;
  }
  .login-offer-grid {
    display: none;
  }
      .logo span{display:inline-block;padding-right: 15px;cursor:pointer;}
      .logo span i{font-size:23px;}
      .form-search {width: 100%;}
      .banner-text p{font-size: 26px;line-height: 45px;text-align: center;width: 70%;padding-top: 19px;margin: 0 auto;color:#fff;}
      .banner-text p span {font-size: 26px;width: 100%;}
      .home-banner{background-position: 20% 0;height:auto;}
      .offer-code {padding: 0px 9px 25px 0px;float:left;width:100%;}
      .coupon-code .offer-group {width: 100%;display: table;}
      .footer-last {float: left;}
      .app-icons ul{padding:0px;}
      p.footer-last {float: none;text-align: center;}
      ul.footer-conditions {float: none;text-align: center;padding: 0px;}
      ul.footer-conditions li {display: inline-block;padding-top: 0px;}
      .footer .row .col-md-6:first-child, .footer .row .col-md-6:nth-child(2){width:100%;}
      .footer .row > .col-md-3 {width:50%;float:left;}
      .footer-three{margin-bottom:45px;}
      .footerlist-two ul li, .footerlist-two ul li a {font-size: 12px;}
      .banner-text video{width:75%;margin-top:25px;}
      .section-home {padding: 0px 0px;}
      .how-work {width: 100%;float: left;margin:0px;}
      .howwork-content h2{height:auto;}
      .row-eq-height{display:block;}
      .offerlist_one {display: block;height: 90px;padding: 10px;}
      .offer-img {padding: 15px 0px 30px 0px;}
      .app-img{max-width:100%;}
      .app-content {max-width: 100%;padding: 5px 10px 5px 10px;}
      .app-content ul{padding:0px;}
      .app-content ul li img{width:120px;}
      .app-content h2{font-size:28px;}
      .section-two,.section-app{padding: 30px 0px;}
      .footer-three {margin-bottom: 0px;}
      .footer-two, .footer-three {margin-top: 30px;}
      .swiper-btn-home .swiper-button-next, .swiper-button-prev{top:68px;}
      .swiper-btn-home .swiper-button-prev, .swiper-container-rtl .swiper-button-next{top:68px;}
      ul.dropdown-menu{left: -130px;}
      .logo span.mob-user ul li img{display:inline-block;padding-right:10px;}
      ul.list-unstyled{background:#fff;}
      ul.list-unstyled li a i{padding-right:10px; width:30px; text-align:center;}
      #sidebar .sidebar-header{background: var(--secondary-color);}
      .dryclean-form{padding: 0px 10px 30px 10px;}
      .dryclean-form h1{margin-bottom: 20px;font-size:21px;}
      .dryclean-form{background-size:100%;}
      .app-content ul li a img{width:120px;}
      .dryclean-form h2{margin-bottom: 20px;line-height: 35px;}
      .vertical-align {display: block !important;}
      .aboutsec, .easelaundry-wrapper { padding: 0px 32px 32px 32px;}
  
      /* home page */
  
      .home_single_search h1{ padding: 0;
        line-height: normal;}
      .home_single_search p{ padding:0 20px;}
      .video_wrapper img{ width:250px;}
      #videoModal .vertical-alignment-helper .modal-content, .offerModal .vertical-alignment-helper .modal-content{ width:90%;}
      #videoModal .vertical-alignment-helper .modal-content, #videoModal .vertical-alignment-helper .modal-content iframe{ height:300px;}
  
      /* price section */
  
      .drclean-header{
          text-align:center;
      }
      .drclean-header span.price-hrs
      {
          float: none !important;
          text-align: center;
          display: block;
          padding-top: 5px;
      }
      .app-section-title p{
          max-width:100%;
      }
      .price-tab-left {
          width: 100%;
          float: left;
      }
      .price-tab-left ul{
          width:100%;
          padding-right:0px;
      }
      .price-tab-right {
          width: 100%;
          margin-top: 0px;
          bottom:0px;
      }
  
      .price-wrapper {
          margin-top:0px !important;
          bottom:0px;
      }
  
      /* price section */
      /* service section */
      .section-service{padding:40px 15px;}
      .ct-service-slider{width:300px !important;}
      /* service section */
  
  
      .xs-mt-32 {
          margin-top:32px;
      }
  
      .xs-only {
      display: block;
      margin-bottom: 23px;
      text-align: center;
      }
  
      .section-title.client-title { opacity:1 !important; transform:none !important;}
      .section-clientlist { margin-top:0px;}
  
      .price-tab-left .nav-tabs .nav-link span {
          width: 77px;
      }
      .wash-fold-card {
        padding: 15px;
        flex-direction: column;
      }
      .wash-fold-info ul li p {
        font-size: 15px;
        margin-bottom: 0;
        margin: 10px 0;
    }
    .aboutsec { background: #681986 !important;  }
     /* pickup page */
     .pickupsteps .pstepsinner .btn-theme, .btn-outline-cancel {
        font-size: 13px;
    }

    .show-card-details .card-cvv .saved-cvv, .show-card-details .card-cvv .savedcard-btn {
        float: left;
        width: 86px;
    }

    .address-blk-card {
        margin-bottom: 23px;
    }

    .vertical-align {
        display: block;
    }

    .add-new-address {
        position: relative;
        bottom: 0px;
        text-align: center;
    }

    .pickupaddress-title ul li:first-child {
        padding-right: 0px;
    }

    .pickup-address h4 {
        font-size: 14px;
    }

    .pickupaddress-title ul li a {
        font-weight: 600;
        font-size: 13px;
    }

    /*.formgrp-custom {width: 50%;display: inline-block;float:left;text-align:center;}*/
    .formgrp-custom p {
        display: none;
    }

    .address_optional {
        border: 0px;
        position: static;
        height: auto;
        margin-bottom: 22px;
    }

    .address_optional p {
        position: static;
        text-align: center;
        margin: 0 auto;
    }

    .pickupaddress-title span.same-pickup {
        width: auto;
        text-align: right;
        float: right;
        display: inline-block;
    }

    ul.default_hide1 {
        visibility: visible;
        bottom: 0px;
    }

    .pickupaddress-title span ul ul {
        margin: 0px;
    }

    .logo a {
        display: inline-block;
    }

    .logo span {
        display: inline-block !important;
        padding-right: 15px;
        cursor: pointer;
    }

    .coupon-code .offer-group {
        width: 100%;
        display: table;
    }

    .footer-last {
        float: left;
    }

    .card-cvv input.address-form {
        width: 100%;
    }

    .card-debit {
        margin: 15px 0px 0px 0px;
    }

    input#cardnumber {
        width: 100%;
    }

    .card-expiry {
        width: 50%;
        padding: 5px 0px 0px 20px;
        text-align: left;
        float: left;
    }

    .card-cvv {
        margin-top: 0px;
    }

    .offer-code {
        padding: 0px 9px 25px 0px;
        float: left;
        width: 100%;
    }

    .form-card {
        width: 100%;
    }

    .card-cvv .formgrp-custom {
        width: 100%;
    }

    .footer .row > .col-md-3 {
        width: 50%;
        float: left;
    }

    .footer .row .col-md-6:first-child, .footer .row .col-md-6:nth-child(2) {
        width: 100%;
        float: none;
    }

    ul.footer-conditions {
        float: none;
        text-align: center;
        padding: 0px;
    }

    p.footer-last {
        float: none;
        text-align: center;
    }

    .mobile_price {
        background: #fff;
        padding: 13px 16px 13px 16px;
        position: fixed;
        bottom: 0px;
        width: 100%;
        left: 0px;
        z-index: 99;
        box-shadow: 2px 2px 10px rgba(91, 91, 91, .8);
        transform: translateY(100%);
        transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    }

    .mobile_price p {
        display: inline-block;
        margin: 0px;
    }

    .mobile_price button {
        background: #ED1C24;
        border: 0px;
        color: #fff;
        padding: 4px 15px 4px 15px;
        float: right;
        border-radius: 3px;
    }

    .sticky-show {
        transform: translateY(0px);
    }

    .savedcards {
        margin: 0px;
    }

    ul.default_hide2 {
        visibility: visible;
        bottom: 0px;
    }

    .saved-cards .card-cvv {
        margin-top: 5px;
        width: 40%;
    }

    .saved-cvv, .savedcard-btn {
        display: block;
    }

    .summary-payment {
        width: 100%;
        margin-top: 30px;
    }

    .show-card-details .card-cvv .saved-cvv, .show-card-details .card-cvv .savedcard-btn {
        float: left;
    }

    .show-card-details .card-cvv .savedcard-btn {
        margin-left: 10px;
    }

    .pickupsteps .editbtn .fa {
        margin-right: 0px;
    }

    /* pickup page */
    
  }
  
  @media only screen and (max-width:575px){
    .work-item .work-inner .work-img span {
        right: 0;
      }
      .work-item {
        width: 100%;
      }
.login-grid {
    width: 100%;
  }
  .login-offer-grid {
    display: none;
  }
    .postcodeform input[type="submit"],.postcodeform .btn,.postcodeform input[type="text"] {
        font-size: 16px;
    }
    .wash-fold-text {
        border-radius: 20px;
        margin: 0 auto;
    }
    .city-list {
        flex: 0 0 100%;
        border-right: 0;
      }
    .areacoverd-proc-steps ul {
        flex-direction: column;
      }
.my-orders {
    padding: 5px;
  }
  .myorder-header {
    padding: 5px;
    flex-direction: column;
  }
  .custom-order-price {
    font-size: 20px;
    margin: 5px 0 0;
  }
  .customorder {
    flex-direction: column;
  }
  span.lft-order {
    font-size: 16px;
  }
  .custom-order {
    margin: 0 0 5px;
  }
  .rht-details {
    font-size: 16px;
  }
  .order-status li {
    margin: 0 0 5px;
    display: block;
    text-align: center;
  }
      .offermodal-body h3{ font-size:30px; padding:0 0 15px;}
      .offermodal-body h4{ font-size:18px;}
      .offermodal-body p{ font-size:14px; line-height:20px;}
      .offermodal-body .offercode{ font-size:30px; height:55px;}
      .offermodal-body .offercode span{ padding:2px 30px;}
      .offermodal-body .offercode:before, .offermodal-body .offercode:after{ height:55px;}
  }
  
  @media only screen and (max-width:479px){
    #site_logo {
        max-height: 30px;
      }
    .areacoverd-proc-steps ul {
        flex-direction: column;
      }
.my-orders {
    padding: 5px;
  }
  .myorder-header {
    padding: 5px;
    flex-direction: column;
  }
  .custom-order-price {
    font-size: 20px;
    margin: 5px 0 0;
  }
  .customorder {
    flex-direction: column;
  }
  span.lft-order {
    font-size: 16px;
  }
  .custom-order {
    margin: 0 0 5px;
  }
  .rht-details {
    font-size: 16px;
  }
  .order-status li {
    margin: 0 0 5px;
    display: block;
    text-align: center;
  }
      /* homepage */
      .logo {
        width: 100%;
      }
      .listcloth-rht span button.quantity-left-minus, .listcloth-rht span button.quantity-right-plus {
        width: 24px;
        height: 24px;
      }
      .logo span{display:inline-block;padding-right: 12px;cursor:pointer;}
      .logo span i{font-size:18px;}
      .form-search {width: 100%;}
      .banner-text{margin-bottom:20px;}
      .banner-text p{font-size: 20px;line-height: 33px;text-align: center;width: 100%;padding-top: 19px;}
      .banner-text p span {font-size: 20px;width: 100%;}
      .offer-code {padding: 0px 9px 25px 0px;float:left;width:100%;}
      .coupon-code .offer-group {width: 100%;display: table;}
      .footer-last {float: left;}
      .app-icons ul{padding:0px;}
      p.footer-last {float: none;text-align: center;}
      ul.footer-conditions {float: none;text-align: center;padding: 0px;}
      ul.footer-conditions li {display: inline-block;padding-top: 0px;}
      .footer .row .col-md-6:first-child, .footer .row .col-md-6:nth-child(2), .footer .row > .col-md-3 {width: 100%;}
      .banner-text video{width: 100%;margin-top: 25px;}
      .app-img, .app-content {max-width: 100%;}
      .logo a img.mob-logo{width: 180px;}
      .logo span.mob-user{padding: 0 0 0 7px;}
    .hvr-icon-wobble-horizontal { padding:5px;}
    .aboutsec, .easelaundry-wrapper { padding: 0px 14px 30px 14px;}
    .offer-wrapper { background: #681986;  }
    .section-header .section-subtitle {
      font-size: 2.3rem;
      margin-bottom: 2.5rem;
      margin-top: 14px;
    }
    .section-header .section-title {
      font-size: 3.2rem;
      text-align: left;
  }
  .section-header .section-title, .section-header .section-subtitle {
      position: relative;
      margin-right: 2.6rem;
      margin-top: 14px;
  }
  .section-header .section-title span {
      left: 0px;
      margin-bottom: 2rem;
      font-size: 1.4rem;
      color: #fff;
    }
  
  .offer-wrapper .carousel-indicators {
      position: absolute;
      bottom: -50px;
  }
  
    /* homepage */
      /* service section  start */
      .ct-service-slider{width:240px !important;}
      /* service section end */
  
  .home_single_search h1{ font-size:24px;}
  .home_single_search p{ padding:0;}
  
  #videoModal .vertical-alignment-helper .modal-content, #videoModal .vertical-alignment-helper .modal-content iframe{ height:200px;}
  /* pickup page */

  .vertical-alignment-helper {
    width: 99%;
}

.signinModal .signin .signin-conent .tabaction {
    margin-top: 14px;
}


.pl-41 {
    padding-left: 0px;
}

/*.payment_choose_option .media-left { display: block; } */
.payment-title {
    padding: 14px;
}

.address_optional {
    border: 0px;
    position: static;
    height: auto;
    margin-bottom: 22px;
}

.address_optional p {
    position: static;
    text-align: center;
    margin: 0 auto;
}

.formgrp-custom {
    width: 100%;
    text-align: center;
}

.pickupaddress-title ul {
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 20px;
}

/*.pickupaddress-title span {width:100%;text-align: left;float: left;}*/
.add-address-form {
    padding: 0px;
}

.pickupsteps .add-address {
    width: 100%;
    text-align: center;
}

.pickupaddress-title ul li:first-child {
    padding-right: 0px;
}

.pickup-address h4 {
    font-size: 14px;
}

.pickupaddress-title ul li a {
    font-weight: 600;
    font-size: 12px;
}

.pickupaddress-title span.same-pickup {
    margin-top: 15px;
    float: left;
    text-align: left;
}

.billing-address {
    width: 100%;
    padding: 0px;
}

.filled-address {
    margin-top: 5px;
}

.card-debit {
    margin: 15px 0px 0px 0px;
}

input#cardnumber {
    width: 100%;
}

.card-expiry {
    width: 100%;
    padding: 5px 0px 0px 20px;
    text-align: left;
}

.card-cvv {
    width: 100%;
    margin-top: 15px;
}

.footer .row > .col-md-3 {
    width: 100%;
    float: left;
}

.footer-two, .footer-three {
    height: auto;
}

.offer-code {
    padding: 0px 9px 25px 0px;
    float: left;
    width: 100%;
}

.card-debit .formgrp-custom {
    width: 100%;
}

.show-card-details {
    padding: 0px;
}

.saved-cards .card-cvv {
    margin-top: 5px;
    width: 100%;
}

.delivery-btn button.back-btn, .delivery-btn button.paybtn {
    font-size: 13px;
    height: 42px;
}

.payment_option span label#debit-card, .payment_option span label#netbanking {
    font-size: 13px;
}

.savedcard .card-cvv div.formgrp-custom {
    margin-top: 0;
}

.show-card-details .card-cvv .savedcard-btn {
    margin: 10px 0 0;
}

.pickupsteps .pstepsinner .editbtn {
    left: 0;
    text-align: center;
}

.logo-img {
    width: 167px;
}

.pickupaddress-title h4 img {
    display: inline-block;
    padding-right: 5px;
}

.pickupaddress-title h4 {
    font-size: 14px;
}

.pickupaddress-title .editbtn {
    border: 0px solid transparent;
    padding: 8px;
    right: 0px;
}

.payment_choose_option .verify-btn {
    padding: 13px 10px;
    margin-left: 5px;
}

/* pickup page */
  }
  
  .dropdowns{
    background: #fff;
    width: 29rem;
    border: 1px solid gray;
}

.eye-btn {
  background: transparent;
  border: 0;
  position: absolute;
  right: 10px;
  top: 26px;
}